import { IProductWithoutDetails } from '../../Product/ProductAssets';

export interface CheckInvoiceQueryParams {
    shippedDate: string | Date
}

export interface DanfeByBarCodeQueryParams {
    skip: number
    take: number
}

export enum InvoiceType {
    SALES,
    SUPPLY
}

export interface InvoiceItem {
    nrItem: number,
    quantity: number,
    price: number,
    total: number,
    product: IProductWithoutDetails
}

export interface IInvoice {
    id: string,
    nrInvoice: number,
    serie: number,
    value: number,
    bpClientTaxId: string,
    bpClientCode: string,
    bpClientStore: string,
    invoiceType: InvoiceType,
    bpCourierTaxId: string,
    bpCourierCode: string,
    bpCourierStore: string,
    danfeBarCode: string,
    emissionDate: Date | string,
    totalItems: number,
    items: InvoiceItem[]
}

export enum ActiveStepCheckout {
	BARCODE,
	PRODUCTS,
}
