import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Grid,
} from '@mui/material';
import { Form, FormikContext, useFormik } from 'formik';
import FileUpload from '../Common/Form/DropzoneUpload';

interface FiscalDocumentEntryXMLModalProps {
  open: boolean;
  handleClose: () => void;
  confirmUploadXml(data: FormData): void;
}

interface FileData {
  file: File;
  preview: string;
}

interface FormValues {
  xmls: FileData[] | null;
}

const FiscalDocumentEntryXMLModal = ({
	open,
	handleClose,
	confirmUploadXml,
}: FiscalDocumentEntryXMLModalProps): JSX.Element => {
	const formik = useFormik<FormValues>({
		initialValues: {
			xmls: null,
		},
		onSubmit: (values: FormValues) => {
			if (!values.xmls) return;

			const formData = new FormData();

			values.xmls.forEach((fileData: FileData) => {
				const file = new File([fileData.file], fileData.file.name, {
					type: 'application/xml',
				});
				formData.append('xmls', file);
			});

			confirmUploadXml(formData);
			handleClose();
			formik.resetForm();
		},
	});

	return (
		<Dialog
			open={open}
			maxWidth="sm"
			fullWidth
			onClose={() => {
				handleClose();
				formik.resetForm();
			}}
		>
			<FormikContext.Provider value={formik}>
				<Form encType="multipart/form-data">
					<DialogTitle>Upload do XML do Documento Fiscal</DialogTitle>
					<DialogContent>
						<Grid container spacing={2} sx={{ mt: 1 }}>
							<Grid item xs={12}>
								<FileUpload
									name="xmls"
									acceptedFileTypes="xml"
									maxFiles={10}
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => {
								handleClose();
								formik.resetForm();
							}}
						>
							Cancelar
						</Button>
						<Button
							type="submit"
							variant="contained"
							disabled={!formik.values.xmls}
						>
							Enviar
						</Button>
					</DialogActions>
				</Form>
			</FormikContext.Provider>
		</Dialog>
	);
};

export default FiscalDocumentEntryXMLModal;
