/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useField } from 'formik';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { InsertDriveFile } from '@mui/icons-material';
import { getBase64Image } from '../../../helpers/Utils';

interface FileUploadProps {
	name: string;
	currentFile?: string;
	acceptedFileTypes?: string;
	maxFiles?: number;
}

const StyledBox = styled(Box)(({ theme }) => ({
	background: theme.palette.background.paper,
	cursor: 'pointer',
	padding: theme.spacing(2),
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: theme.shape.borderRadius,
	border: `2px dashed ${theme.palette.divider}`,
}));

const FileUpload = ({
	name, currentFile, acceptedFileTypes, maxFiles = 1,
}: FileUploadProps): JSX.Element => {
	const [field, meta, helpers] = useField(name);

	const acceptedFiles = useMemo(() => {
		if (acceptedFileTypes === 'xml') {
			return '.xml,application/xml';
		}
		return 'image/jpeg, image/png, image/bmp, image/gif';
	}, [acceptedFileTypes]);

	useEffect(() => {
		const base64File = currentFile && getBase64Image(currentFile);

		if (base64File) {
			fetch(base64File)
				.then((res) => res.blob())
				.then((blob) => {
					const file = new File([blob], 'logo.png', { type: 'image/png' });

					helpers.setValue({
						file,
						preview: base64File,
					});
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFile]);

	const { getRootProps, getInputProps, fileRejections } = useDropzone({
		accept: acceptedFiles,
		maxSize: !acceptedFileTypes ? 2 * 1024 * 1024 : undefined,
		maxFiles,
		multiple: maxFiles > 1,
		onDrop: (acceptedFilesDrop) => {
			if (acceptedFileTypes === 'xml') {
				if (maxFiles > 1) {
					const filePromises = acceptedFilesDrop.map((file) => new Promise((resolve) => {
						const reader = new FileReader();
						reader.onload = (item) => {
							resolve({
								file,
								preview: item.target?.result,
							});
						};
						reader.readAsDataURL(file);
					}));

					Promise.all(filePromises).then((newFiles) => {
						const currentFiles = Array.isArray(field.value) ? field.value : [];
						helpers.setValue([...currentFiles, ...newFiles]);
					});
				}
			} else {
				const file = acceptedFilesDrop[0];

				if (file) {
					const reader = new FileReader();

					reader.onload = (item) => {
						helpers.setValue({
							file,
							preview: item.target?.result,
						});
					};

					reader.readAsDataURL(file);
				}
			}
		},
	});

	const fileRejectionItems = useMemo(() => (
		fileRejections.map(({ file, errors }) => (
			<Typography key={file.name} color="error" variant="body2">
				{file.name}
				{errors.map((e) => e.message).join(', ')}
			</Typography>
		))
	), [fileRejections]);

	const handleRemove = useCallback((fileNameToRemove: string) => {
		const currentFiles = Array.isArray(field.value) ? field.value : [];
		const updatedFiles = currentFiles.filter((fileData) => fileData.file.name !== fileNameToRemove);
		helpers.setValue(updatedFiles);
	}, [field.value, helpers]);

	const thumbs = useMemo(() => {
		if (field.value) {
			if (acceptedFileTypes === 'xml') {
				const files = Array.isArray(field.value) ? field.value : [field.value];

				return files.map((fileData) => (
					<Grid item xs={12} key={fileData.file.name}>
						<Paper
							elevation={2}
							sx={{
								padding: 2,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
								<InsertDriveFile color="primary" />
								<Typography>{fileData.file.name}</Typography>
							</Box>
							<Tooltip title="Remover arquivo">
								<IconButton onClick={() => handleRemove(fileData.file.name)}>
									<CloseIcon />
								</IconButton>
							</Tooltip>
						</Paper>
					</Grid>
				));
			}

			return (
				<Grid item xs={3}>
					{field.value.preview && (
						<Paper elevation={2} style={{ position: 'relative' }}>
							<img
								alt="Imagem selecionada"
								src={field.value.preview}
								style={{ width: '100%', height: 'auto' }}
							/>
							<Tooltip title="Remover imagem">
								<IconButton
									onClick={() => handleRemove(field.value.name)}
									style={{
										position: 'absolute',
										right: 0,
										top: 0,
									}}
								>
									<CloseIcon />
								</IconButton>
							</Tooltip>
						</Paper>
					)}
				</Grid>
			);
		}

		return null;
	}, [field.value, handleRemove, acceptedFileTypes]);

	return (
		<Box>
			<StyledBox {...getRootProps()}>
				<input {...getInputProps()} />
				<Typography variant="body2">
					{acceptedFileTypes === 'xml'
						? 'Arraste e solte o arquivo XML aqui, ou clique para selecionar'
						: 'Arraste e solte a imagem aqui, ou clique para selecionar'}
				</Typography>
			</StyledBox>
			<Typography variant="caption" display="block" marginTop={1}>
				{acceptedFileTypes === 'xml'
					? `Tipos suportados: XML. Máximo ${maxFiles} arquivo(s)`
					: 'Tipos suportados: JPG, PNG, BMP, GIF. Tamanho máximo: 2MB'}
			</Typography>
			<Grid container spacing={2} marginTop={2}>
				{thumbs}
				{fileRejectionItems.length > 0 && (
					<Box sx={{ mt: 2, ml: 2 }}>
						<Typography variant="body2" color="error">
							{acceptedFileTypes === 'xml' ? 'Erro ao carregar XML' : 'Erro ao carregar imagem:'}
							{fileRejectionItems}
						</Typography>
					</Box>
				)}
			</Grid>
			{meta.touched && meta.error ? (
				<Typography color="error" variant="body2" marginTop={2}>{meta.error}</Typography>
			) : null}
		</Box>
	);
};

FileUpload.defaultProps = {
	currentFile: undefined,
	acceptedFileTypes: undefined,
	maxFiles: 1,
};

export default FileUpload;
