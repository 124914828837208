import React, {
	Dispatch,
	Reducer,
	useReducer,
} from 'react';
import ProviderPresentational from '../../components/Provider/Provider';
import { getProviders, approveProvider } from '../../services/provider';

enum ActionType {
	LOADING,
	PROVIDERS,
}

interface IState {
	loading: boolean;
	providers: any[];
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.PROVIDERS; payload: { providers: any[] } };

interface IProviderActions {
	setLoading(loading: boolean): void;
    getProviders(): void;
    approveProvider(id: string): void;
}

const initialState: IState = {
	loading: false,
	providers: [],
};

const reducer: React.Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return {
				...state,
				loading: action.payload.loading,
			};
		case ActionType.PROVIDERS:
			return {
				...state,
				providers: action.payload.providers,
			};
		default:
			throw new Error();
	}
};

const ProviderActions = (dispatch: Dispatch<TAction>): IProviderActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getProviders() {
			actions.setLoading(true);
			getProviders().then((providers) => {
				dispatch({
					type: ActionType.PROVIDERS,
					payload: { providers: providers.data.data },
				});
				actions.setLoading(false);
			});
		},
		approveProvider(id: string) {
			actions.setLoading(true);
			approveProvider(id).then(() => {
				actions.setLoading(false);
			});
		},
	};

	return actions;
};

const Provider = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const actions = ProviderActions(dispatch);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <ProviderPresentational {...state} {...actions} />;
};

export default React.memo(Provider);
