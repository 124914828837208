import React, { Dispatch, useReducer } from 'react';
import ClientEditPresentational from '../../components/Client/ClientEdit';
import { ProviderClient } from '../../components/Common/ProviderClient/ProviderClientAssets';

enum ActionType {
	LOADING,
	CLIENT,
}

interface IState {
	loading: boolean;
	client: any;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.CLIENT; payload: { client: any } };

interface IClientActions {
	setLoading(loading: boolean): void;
    getClientById(id: string): void;
    saveClient({ id, data }: {
        id?: string | undefined;
        data: ProviderClient;
    }): void;
}

const initialState: IState = {
	loading: false,
	client: null,
};

const reducer: React.Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.CLIENT:
			return { ...state, client: action.payload.client };
		default:
			throw new Error();
	}
};

const ClientActions = (dispatch: Dispatch<TAction>): IClientActions => ({
	setLoading(loading: boolean) {
		dispatch({ type: ActionType.LOADING, payload: { loading } });
	},
	getClientById(id: string) {
		this.setLoading(true);
		// getClientById(id).then(response => {
		//   dispatch({
		// 	type: ActionType.CLIENTS,
		// 	payload: { clients: response.data.data, count: response.data.count }
		// })
		//   this.setLoading(false);
		// });
	},
	saveClient({ id, data }: { id?: string; data: ProviderClient }) {
		this.setLoading(true);

		if (id) {
			this.setLoading(false);
		} else {
			this.setLoading(false);
		}
	},
});

const Client = (): JSX.Element => {
	const [state, dispatch] = useReducer<React.Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const actions = ClientActions(dispatch);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <ClientEditPresentational {...state} {...actions} />;
};

export default Client;
