import React, { useCallback } from 'react';
import { Form, Formik } from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Input from '../Common/Form/Input';
import { DatePicker } from '../Common/Form/DatePicker';
import Autocomplete from '../Common/Form/Autocomplete';
import { statusList } from '../../constants/statusInvoiceLabels';
import { StatusInvoice } from '../../containers/FiscalDocumentEntry/FiscalDocumentEntryAssets';
import { filterObject } from '../../helpers/Utils';
import { FiscalDocumentQueryParams } from '../../interfaces/FiscalDocumentParams';

interface IForm {
	nrInvoice?: string;
    status?: StatusInvoice[];
	businessPartnerName?: string;
    emissionDate?: Date | string;
}

interface IFilterProps {
	sendFilter: (values: Partial<FiscalDocumentQueryParams>) => void;
	initialValues: IForm;
}

const FiscalDocumentEntryFilter = ({
	sendFilter,
	initialValues,
}: IFilterProps): JSX.Element => {
	const onSubmit = useCallback((values: IForm) => {
		sendFilter(filterObject(values));
	}, [sendFilter]);

	const onReset = useCallback((handleReset) => {
		sendFilter({});
		handleReset();
	}, [sendFilter]);

	return (
		<Paper sx={{ p: 2, width: '100%', mb: 2 }}>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
			>
				{({ handleReset }) => (
					<Form>
						<Grid container spacing={2} sx={{ p: 2, marginTop: 6 }}>
							<Grid item xs={12}>
								<DatePicker name="emissionDate" label="Data da Emissão" />
							</Grid>
							<Grid item xs={12}>
								<Input.InputField
									id="nrInvoice"
									name="nrInvoice"
									label="Nº da Nota Fiscal"
									autoComplete="off"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12}>
								<Input.InputField
									id="businessPartnerName"
									name="businessPartnerName"
									label="Nome do Emitente"
									autoComplete="off"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12}>
								<Autocomplete
									label="Status"
									name="status"
									valueKey="id"
									valueLabel="id"
									labelKey="label"
									options={statusList}
									multiple
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
								<Button variant="outlined" onClick={() => onReset(handleReset)}>
									Restaurar
								</Button>
								<Button variant="contained" type="submit">
									Filtrar
								</Button>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</Paper>
	);
};

export default FiscalDocumentEntryFilter;
