import React, { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
	DataGrid,
	GridSortModel,
} from '@mui/x-data-grid';
import {
	Typography,
} from '@mui/material';
import { ISaleOrder } from '../../containers/SaleOrder/SaleOrderAssets';
import { useManageColumns } from '../../hooks/useManageColumns';
import useColumns from '../../hooks/useColumns';
import { saleOrderColumns } from '../../constants/saleOrderColumns';
import { ToolbarComponent } from '../Common/Datagrid/DataGridToolBar';
import ActionJustificativeModal from './Modals/ActionJustificativeModal';

interface SaleOrderProps {
	loading?: boolean;
	rows: ISaleOrder[];
	saleOrdersPages: number,
	saleOrdersPage: number,
	saleOrdersTake: number,
	sortModel: GridSortModel;
	onChangePage(saleOrdersTake: number): void;
	onChangePageSize(pageSize: number): void;
	onSortModelChange(sortModel: GridSortModel): void
	onDetailsClick: (id: string) => void;
	handleEdit: (id: string) => void;
	handleCancelSaleOrder: (id: string, actionJustificative: string) => void;
}

const columnsVisibility = [
	'code',
	'createdAt',
	'clientName',
	'salesman',
	'totalOrder',
	'status',
	'actions',
];

const localStorageKey = 'saleOrderColumns';

const SaleOrderTable = ({
	loading,
	rows,
	saleOrdersPages,
	saleOrdersPage,
	saleOrdersTake,
	sortModel,
	onChangePage,
	onChangePageSize,
	onSortModelChange,
	onDetailsClick,
	handleEdit,
	handleCancelSaleOrder,
}: SaleOrderProps): JSX.Element => {
	const [openDialog, setOpenDialog] = useState(false);
	const [currentSaleOrderId, setCurrentSaleOrderId] = useState<string | null>(null);

	const handleCancel = useCallback((id: string) => {
		setCurrentSaleOrderId(id);
		setOpenDialog(true);
	}, []);

	const handleCloseDialog = useCallback(() => {
		setOpenDialog(false);
		setCurrentSaleOrderId(null);
	}, []);

	const handleConfirmDialog = useCallback((justification: string) => {
		if (currentSaleOrderId) {
			handleCancelSaleOrder(currentSaleOrderId, justification);
		}
		handleCloseDialog();
	}, [currentSaleOrderId, handleCancelSaleOrder, handleCloseDialog]);

	const { columns, defaultVisibility } = useColumns({
		columnsVisibility,
		defaultColumnsOverride: saleOrderColumns,
		includeStatusColumn: false,
		onEditClick: handleEdit,
		onCancelClick: handleCancel,
		onDetailsClick,
	});

	const {
		currentColumns,
		columnVisibilityModel,
		handleColumnVisibilityModelChange,
		onRestoreClick,
	} = useManageColumns({
		initialColumns: columns,
		initialVisibility: defaultVisibility,
		localStorageKey,
		isDraggable: true,
	});

	return (
		<Box component={Paper} sx={{ width: '100%' }}>
			{loading && <Typography>Loading</Typography>}
			<DataGrid
				autoHeight
				rows={rows}
				rowCount={saleOrdersPages}
				pagination
				paginationMode="server"
				sortingMode="server"
				columns={currentColumns}
				page={saleOrdersPage}
				pageSize={saleOrdersTake}
				rowsPerPageOptions={[10, 25, 50, 100]}
				loading={loading}
				sortModel={sortModel}
				onPageChange={onChangePage}
				onPageSizeChange={onChangePageSize}
				onSortModelChange={onSortModelChange}
				columnVisibilityModel={columnVisibilityModel}
				onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
				components={{ Toolbar: ToolbarComponent(onRestoreClick) }}
				disableSelectionOnClick
				disableColumnMenu
			/>
			<ActionJustificativeModal
				open={openDialog}
				onClose={handleCloseDialog}
				onConfirm={handleConfirmDialog}
			/>
		</Box>
	);
};

SaleOrderTable.defaultProps = {
	loading: false,
};

export default SaleOrderTable;
