import React, {
	useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Box, Paper, Typography } from '@mui/material';
import {
	DataGrid, GridRowParams, GridSortDirection,
} from '@mui/x-data-grid';
import { FilterList } from '@mui/icons-material';
import { IStatusSaleOrder, StatusCount, StatusOperation } from '../../containers/StatusSaleOrder/StatusSaleOrderAssets';
import useDataGridManagement from '../../hooks/useDataGridManagement';
import { StatusSaleOrderQueryParams } from '../../interfaces/StatusSaleOrderParams';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import DrawerFilter from '../Common/DrawerFilter';
import { normalizeDataGridOrderBy } from '../../helpers/Utils';
import { ToolbarComponent } from '../Common/Datagrid/DataGridToolBar';
import { useManageColumns } from '../../hooks/useManageColumns';
import { statusSaleOrderColumns } from '../../constants/statusSaleOrderColumns';
import StatusSaleOrderFilter from './StatusSaleOrderFilter';
import { StatusOperationTypeLabels } from '../../constants/statusOperationTypeLabels';
import useColumns from '../../hooks/useColumns';

interface StatusSaleOrderProps {
	loading: boolean;
	statusSaleOrder: IStatusSaleOrder[];
	getStatusSaleOrder: (params: StatusSaleOrderQueryParams) => void;
	getStatusSaleOrderCache(): void;
	statusSaleOrderTake: number;
	statusSaleOrderPage: number;
	statusSaleOrderPages: number;
	statusCounts: StatusCount[];
}

const localStorageKey = 'statusSaleOrderColumns';

const columnsVisibility = [
	'code',
	'emissionDate',
	'clientName',
	'courierName',
	'totalOrder',
	'clientCode',
	'clientTaxId',
	'statusOperation',
	'shippedUserName',
	'shippedDate',
	'invoice',
];

const optionalColumnsVisibility = [
	'clientBranch',
];

const styleDataGrid = {
	'& .green': { backgroundColor: '#d4edda !important', color: '#000' },
	'& .yellow': { backgroundColor: '#fff3cd !important', color: '#000' },
	'& .red': { backgroundColor: '#f8d7da !important', color: '#000' },
	'& .blue': { backgroundColor: '#d1ecf1 !important', color: '#000' },
	'& .MuiDataGrid-row': {
		'&.green': { backgroundColor: '#d4edda !important' },
		'&.yellow': { backgroundColor: '#fff3cd !important' },
		'&.red': { backgroundColor: '#f8d7da !important' },
		'&.blue': { backgroundColor: '#d1ecf1 !important' },
	},
	'& .MuiDataGrid-columnHeader': {
		fontWeight: 500,
	},
};

const getRowClassName = (params: GridRowParams): string => {
	if (params.row.statusOperation === StatusOperation.OPEN) {
		return 'green';
	} if (params.row.statusOperation === StatusOperation.RELEASED) {
		return 'yellow';
	} if (params.row.statusOperation === StatusOperation.INVOICED) {
		return 'red';
	} if (params.row.statusOperation === StatusOperation.SHIPPED) {
		return 'blue';
	}
	return '';
};

const getStatusColor = (operation: StatusOperation): string => {
	switch (operation) {
		case StatusOperation.OPEN:
			return '#d4edda'; // verde
		case StatusOperation.RELEASED:
			return '#fff3cd'; // amarelo
		case StatusOperation.INVOICED:
			return '#f8d7da'; // vermelho
		case StatusOperation.SHIPPED:
			return '#d1ecf1'; // azul
		default:
			return '#ffffff'; // cor padrão
	}
};

const REFRESH_TIME = 1000 * 60; // 1 minuto

const StatusSaleOrder = ({
	loading,
	statusSaleOrder,
	getStatusSaleOrder,
	getStatusSaleOrderCache,
	statusSaleOrderTake,
	statusCounts,
	statusSaleOrderPage,
	statusSaleOrderPages,
}: StatusSaleOrderProps): JSX.Element => {
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [filterValues, setFilterValues] = useState<Partial<StatusSaleOrderQueryParams>>({});
	const containerRef = useRef<HTMLDivElement>(null);
	const [timeUntilRefresh, setTimeUntilRefresh] = useState(60);

	useEffect(() => {
		const refreshInterval = setInterval(() => {
			getStatusSaleOrderCache();
			setTimeUntilRefresh(60);
		}, REFRESH_TIME);

		const countdownInterval = setInterval(() => {
			setTimeUntilRefresh((prev) => {
				if (prev <= 0) return 60;
				return prev - 1;
			});
		}, 1000);

		return () => {
			clearInterval(refreshInterval);
			clearInterval(countdownInterval);
		};
	}, [getStatusSaleOrderCache]);

	const {
		setFilter,
		sortModel,
		onChangePage,
		onChangePageSize,
		onSortModelChange,
	} = useDataGridManagement<StatusSaleOrderQueryParams>({
		initialPageSize: statusSaleOrderTake,
		initialPage: statusSaleOrderPage,
		fetchData: getStatusSaleOrder,
	});

	const sendFilter = useCallback((values: Partial<StatusSaleOrderQueryParams>) => {
		const pageFilter = {
			...values,
			skip: 0,
			take: statusSaleOrderTake,
			orderBy: normalizeDataGridOrderBy(sortModel) as {
                name: GridSortDirection,
                value: GridSortDirection,
            },
		};
		setFilter(pageFilter);
	}, [setFilter, sortModel, statusSaleOrderTake]);

	const toggleFilterDrawer = useCallback(() => {
		setFilterDrawerOpen(!isFilterDrawerOpen);
	}, [isFilterDrawerOpen]);

	const handleFilterSubmit = useCallback((newFilterValues: Partial<StatusSaleOrderQueryParams>) => {
		setFilterValues(newFilterValues);
		setFilterDrawerOpen(false);
		sendFilter(newFilterValues);
	}, [sendFilter]);

	const countActiveFilters = useCallback((
		filters: Partial<StatusSaleOrderQueryParams>,
	): number => Object.entries(filters)
		.filter(([key, value]) => {
			const excludedKeys = ['skip', 'take'];
			if (excludedKeys.includes(key)) {
				return false;
			}
			return value !== '' && value !== undefined;
		})
		.length, []);

	const headerButtonsProps = useMemo((): PageHeaderButtonProps[] => [
		{
			variant: 'contained',
			color: 'primary',
			onClick: toggleFilterDrawer,
			badgeContent: countActiveFilters(filterValues),
			badgecolor: 'error',
			text: 'Filtro',
			startIcon: <FilterList />,
		},
	], [countActiveFilters, filterValues, toggleFilterDrawer]);

	const pageHeaderMemo = useMemo(() => (
		<PageHeader title="Status do Pedido" buttons={headerButtonsProps} />
	), [headerButtonsProps]);

	const filterMemo = useMemo(() => (
		<DrawerFilter open={isFilterDrawerOpen} onClose={toggleFilterDrawer}>
			<StatusSaleOrderFilter
				sendFilter={handleFilterSubmit}
				initialValues={filterValues}
			/>
		</DrawerFilter>
	), [filterValues, handleFilterSubmit, isFilterDrawerOpen, toggleFilterDrawer]);

	const { columns, defaultVisibility } = useColumns({
		columnsVisibility,
		optionalColumnsVisibility,
		defaultColumnsOverride: statusSaleOrderColumns,
		includeStatusColumn: false,
	});

	const {
		currentColumns,
		columnVisibilityModel,
		handleColumnVisibilityModelChange,
		onRestoreClick,
	} = useManageColumns({
		initialColumns: columns,
		initialVisibility: defaultVisibility,
		localStorageKey,
		isDraggable: true,
	});

	const handleToggleFullscreen = useCallback(() => {
		if (!document.fullscreenElement) {
			containerRef.current?.requestFullscreen();
		} else {
			document.exitFullscreen();
		}
	}, []);

	const formatTime = useCallback((seconds: number) => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
	}, []);

	return (
		<Box>
			{pageHeaderMemo}
			{filterMemo}
			<Box ref={containerRef} component={Paper} sx={{ p: 2, width: '100%' }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
					<Typography>
						{`Atualização automática em: ${formatTime(timeUntilRefresh)}`}
					</Typography>
					<Box sx={{ display: 'flex', gap: 2 }}>
						{statusCounts.map(({ statusOperation, count }) => (
							<Box key={statusOperation} sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
								<Box sx={{
									width: 16,
									height: 16,
									backgroundColor: getStatusColor(statusOperation as StatusOperation),
									mr: 1,
								}}
								/>
								<Typography>
									{`${StatusOperationTypeLabels[statusOperation]}s: ${count}`}
								</Typography>
							</Box>
						))}
					</Box>
				</Box>
				<DataGrid
					autoHeight
					rows={statusSaleOrder}
					rowCount={statusSaleOrderPages}
					pagination
					paginationMode="server"
					sortingMode="server"
					columns={currentColumns}
					page={statusSaleOrderPage}
					pageSize={statusSaleOrderTake}
					rowsPerPageOptions={[10, 25, 50, 100]}
					loading={loading}
					sortModel={sortModel}
					onPageChange={onChangePage}
					onPageSizeChange={onChangePageSize}
					onSortModelChange={onSortModelChange}
					getRowClassName={getRowClassName}
					columnVisibilityModel={columnVisibilityModel}
					onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
					components={{ Toolbar: ToolbarComponent(onRestoreClick, handleToggleFullscreen) }}
					disableSelectionOnClick
					disableColumnMenu
					sx={styleDataGrid}
				/>
			</Box>
		</Box>
	);
};

export default StatusSaleOrder;
