import React from 'react';
import {
	GridColDef,
} from '@mui/x-data-grid';
import { format } from 'date-fns';
import TruncateTooltipCell from '../components/Common/Datagrid/TruncateTooltipCell';

export const userColumns: GridColDef[] = [
	{
		field: 'name',
		headerName: 'Nome',
		flex: 1.5,
		renderCell: (params) => <TruncateTooltipCell value={params.value} />,
	},
	{
		field: 'login',
		headerName: 'Login',
		flex: 1,
		sortable: false,
		renderCell: (params) => <TruncateTooltipCell value={params.value} />,
	},
	{
		field: 'email',
		headerName: 'Email',
		flex: 1.5,
		sortable: false,
		renderCell: (params) => <TruncateTooltipCell value={params.value} />,
	},
	{
		field: 'phone',
		headerName: 'Telefone',
		flex: 1,
		sortable: false,
		renderCell: (params) => <TruncateTooltipCell value={params.value} />,
	},
	{
		field: 'userCreated',
		headerName: 'Quem criou',
		flex: 1,
		sortable: false,
		renderCell: (params) => <TruncateTooltipCell value={params.row.userCreated?.login ?? '-'} />,
	},
	{
		field: 'createdAt',
		headerName: 'Criado em',
		flex: 1,
		sortable: false,
		renderCell: (params) => <TruncateTooltipCell value={params.value ? format(new Date(params.value), 'dd/MM/yyyy HH:mm') : '-'} />,
	},
	{
		field: 'userUpdated',
		headerName: 'Quem atualizou',
		flex: 1,
		sortable: false,
		renderCell: (params) => <TruncateTooltipCell value={params.row.userUpdated?.login ?? '-'} />,
	},
	{
		field: 'updatedAt',
		headerName: 'Atualizado em',
		flex: 1,
		sortable: false,
		renderCell: (params) => <TruncateTooltipCell value={params.value ? format(new Date(params.value), 'dd/MM/yyyy HH:mm') : '-'} />,
	},
	{
		field: 'userActivated',
		headerName: 'Quem ativou',
		flex: 1,
		sortable: false,
		renderCell: (params) => <TruncateTooltipCell value={params.row.userActivated?.login ?? '-'} />,
	},
	{
		field: 'dateActivated',
		headerName: 'Ativado em',
		flex: 1,
		sortable: false,
		renderCell: (params) => <TruncateTooltipCell value={params.value ? format(new Date(params.value), 'dd/MM/yyyy HH:mm') : '-'} />,
	},
	{
		field: 'userDeactivated',
		headerName: 'Quem desativou',
		flex: 1,
		sortable: false,
		renderCell: (params) => <TruncateTooltipCell value={params.row.userDeactivated?.login ?? '-'} />,
	},
	{
		field: 'dateDeactivated',
		headerName: 'Desativado em',
		flex: 1,
		sortable: false,
		renderCell: (params) => <TruncateTooltipCell value={params.value ? format(new Date(params.value), 'dd/MM/yyyy HH:mm') : '-'} />,
	},
];
