import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
	DataGrid,
	GridActionsCellItem,
	GridCellParams,
	GridSortModel,
} from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { User, CurrentUserProps } from '../../containers/User/UserAssets';
import { ToolbarComponent } from '../Common/Datagrid/DataGridToolBar';
import { useManageColumns } from '../../hooks/useManageColumns';
import useColumns from '../../hooks/useColumns';
import { userColumns } from '../../constants/userColumns';

interface UserTableProps {
	loading?: boolean;
	rows: User[];
	usersPages: number;
	usersPage: number;
	usersTake: number;
	sortModel: GridSortModel;
	onChangePage(usersTake: number): void;
	onChangePageSize(pageSize: number): void;
	onSortModelChange(sortModel: GridSortModel): void;
	onResetPassword: (user: CurrentUserProps) => void;
	onChangeActiveStatus(id: string, active: boolean): void;
	handleEdit(id: string): void;
}

const UserTable = ({
	rows,
	loading,
	usersPages,
	usersPage,
	usersTake,
	sortModel,
	onChangePage,
	onChangePageSize,
	onSortModelChange,
	onResetPassword,
	handleEdit,
	onChangeActiveStatus,
}: UserTableProps): JSX.Element => {
	const renderToggleActiveButton = useCallback((params: GridCellParams): JSX.Element => {
		const isActive = params.row.active;
		const onClick = (): void => onChangeActiveStatus(params.id as string, !isActive);
		const color = isActive ? 'primary' : 'default';
		const tooltipTitle = isActive ? 'Bloquear usuário' : 'Desbloquear usuário';
		const actionLabel = isActive ? 'Bloquear usuário' : 'Desbloquear usuário';
		const IconComponent = isActive ? ToggleOnIcon : ToggleOffIcon;

		return (
			<Tooltip title={tooltipTitle}>
				<GridActionsCellItem
					label={actionLabel}
					icon={<IconComponent />}
					color={color}
					onClick={onClick}
				/>
			</Tooltip>
		);
	}, [onChangeActiveStatus]);

	const localStorageKey = 'userColumns';

	const columnsVisibility = [
		'name',
		'login',
		'email',
		'phone',
		'actions',
	];

	const optionalColumnsVisibility = [
		'createdAt',
		'updatedAt',
		'dateDeactivated',
		'dateActivated',
		'userCreated',
		'userUpdated',
		'userActivated',
		'userDeactivated',
	];

	const { columns, defaultVisibility } = useColumns({
		columnsVisibility,
		defaultColumnsOverride: userColumns,
		includeStatusColumn: false,
		onEditClick: handleEdit,
		onResetPassword,
		optionalColumnsVisibility,
		onChangeActiveStatus,
		renderToggleActiveButton,
	});

	const {
		currentColumns,
		columnVisibilityModel,
		handleColumnVisibilityModelChange,
		onRestoreClick,
	} = useManageColumns({
		initialColumns: columns,
		initialVisibility: defaultVisibility,
		localStorageKey,
		isDraggable: true,
	});

	return (
		<Box component={Paper} sx={{ width: '100%' }}>
			<DataGrid
				autoHeight
				rows={rows}
				rowCount={usersPages}
				pagination
				paginationMode="server"
				sortingMode="server"
				columns={currentColumns}
				page={usersPage}
				pageSize={usersTake}
				rowsPerPageOptions={[10, 25, 50, 100]}
				loading={loading}
				sortModel={sortModel}
				onPageChange={onChangePage}
				onPageSizeChange={onChangePageSize}
				onSortModelChange={onSortModelChange}
				columnVisibilityModel={columnVisibilityModel}
				onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
				components={{ Toolbar: ToolbarComponent(onRestoreClick) }}
				disableSelectionOnClick
				disableColumnMenu
			/>
		</Box>
	);
};

UserTable.defaultProps = {
	loading: false,
};

export default UserTable;
