import React, { useCallback } from 'react';
import {
	Box,
} from '@mui/material';
import { FormikContext, Form, useFormik } from 'formik';
import CheckoutBarCode from './CheckoutBarCode';
import CheckoutProducts from './CheckoutProducts';
import {
	ActiveStepCheckout, DanfeByBarCodeQueryParams, IInvoice,
} from '../../../containers/Mobile/CheckoutCarrier/CheckoutCarrierAssets';

export const initialValues = {
	danfeBarCode: '',
	invoiceId: '',
};

interface CheckoutCarrierProps {
	invoice?: IInvoice
	getDanfeByBarCode(danfeBarCode: string, params: DanfeByBarCodeQueryParams): void;
	confirmInvoiceItems(invoiceId: string, shippedDate: string | Date): void;
	loading: boolean;
	setActiveStep(activeStep: ActiveStepCheckout): void;
	activeStep: ActiveStepCheckout;
	invoicePage: number;
	invoiceTake: number;
}

const CheckoutCarrier = ({
	invoice,
	loading,
	activeStep,
	invoicePage,
	invoiceTake,
	getDanfeByBarCode,
	confirmInvoiceItems,
	setActiveStep,
}: CheckoutCarrierProps): JSX.Element => {
	const formik = useFormik({
		initialValues,
		onSubmit: () => {
			if (invoice) {
				confirmInvoiceItems(
					invoice?.id,
					new Date(),
				);
				formik.setFieldValue('danfeBarCode', '');
			}
		},
	});

	const handleConfirmBarCode = useCallback((barCode: string) => {
		getDanfeByBarCode(barCode, {
			skip: invoicePage,
			take: invoiceTake,
		});
	}, [getDanfeByBarCode, invoicePage, invoiceTake]);

	const handleCancelProducts = useCallback(() => {
		setActiveStep(ActiveStepCheckout.BARCODE);
		formik.setFieldValue('danfeBarCode', '');
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				<Box>
					{activeStep === ActiveStepCheckout.BARCODE
				&& (
					<CheckoutBarCode handleConfirmBarCode={handleConfirmBarCode} />
				)}

					{activeStep === ActiveStepCheckout.PRODUCTS
				&& (
					<CheckoutProducts
						invoice={invoice}
						handleCancelProducts={handleCancelProducts}
						loading={loading}
						invoicePage={invoicePage}
						invoiceTake={invoiceTake}
						getDanfeByBarCode={getDanfeByBarCode}
					/>
				)}
				</Box>
			</Form>
		</FormikContext.Provider>
	);
};

CheckoutCarrier.defaultProps = {
	invoice: undefined,
};

export default CheckoutCarrier;
