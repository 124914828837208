import { validationMessage } from '../../../helpers/yupMessages';
import Yup from '../../../helpers/yupMethods';
import { IMeasure } from '../../Product/ProductAssets';
import { ITask } from '../TaskAssets';

export const validationSchema: Yup.AnySchema = Yup.object().shape({
	barCodeOrigin: Yup.string().required(validationMessage.required),
});

export const validationLocationSchema: Yup.AnySchema = Yup.object().shape({
	barCodeLocation: Yup.string().required(validationMessage.required),
});

export interface GroupTasks {
	barCode: string;
	tasks: ITask[];
}

export interface TaskStorageFormik {
	confirmedTasks: ITask[];
	[key: string]: any;
}

export interface LocationOriginQueryParams {
    skip: number
    take: number
}

export interface ProductsByLocationQueryParams {
    skip: number
    take: number
}

export interface IProductTask {
	barCode: string
	code: string
	description: string
	id: string
	taskId: string
	quantity: number
	measures: IMeasure[]
}
