import React, {
	useCallback, useEffect, useState,
} from 'react';
import {
	Box,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Grid,
	Button,
	IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Add, Remove,
} from '@mui/icons-material';
import { Form, FormikContext, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import Input from '../../Common/Form/Input';
import useConfirmationDialog from '../../../hooks/useConfirmationDialog';
import { SaveTaskType } from '../../../services/storage';
import { IProductTask, LocationOriginQueryParams } from '../../../containers/Mobile/Storage/StorageAssets';
import { InventoryTaskStatus } from '../../../enums/InventoryTaskStatus';
import { Loading } from '../../Common/Loading';
import StepperPagination from '../../Common/StepperPagination';
import { formatBarCode } from '../../../helpers/masks';

interface StorageProductsProps {
	getProductsByLocationBarCode: (
	barCode: string,
	status: InventoryTaskStatus,
	params: LocationOriginQueryParams
) => void;
  saveTaskCount: (data: SaveTaskType) => void;
  productTasks: IProductTask[];
  loading: boolean;
  taskPages: number;
  taskPage: number;
  taskTake: number;
}

const StorageProducts = ({
	getProductsByLocationBarCode,
	productTasks,
	saveTaskCount,
	loading,
	taskPages,
	taskPage,
	taskTake,
}: StorageProductsProps): JSX.Element => {
	const [expanded, setExpanded] = useState<null | string>(null);
	const { enqueueSnackbar } = useSnackbar();
	const { requestConfirm, confirmationDialog } = useConfirmationDialog();

	const { locationBarCode } = useParams();
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			productBarCode: '',
			quantity: 0,
			taskId: '',
		},
		onSubmit: (values) => saveTaskCount({
			taskId: values.taskId,
			quantity: values.quantity,
		}),
	});

	useEffect(() => {
		if (productTasks.length > 0) {
			setExpanded(productTasks[0].id);
			formik.setFieldValue('taskId', productTasks[0].id);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productTasks, formik.setFieldValue]);

	useEffect(() => {
		if (locationBarCode) {
			getProductsByLocationBarCode(locationBarCode, InventoryTaskStatus.OPERATING, {
				skip: 0,
				take: taskTake,
			});
		}
	}, [getProductsByLocationBarCode, locationBarCode, taskTake]);

	const handlePageChange = useCallback((newPage: number): void => {
		setExpanded(null);
		if (locationBarCode) {
			getProductsByLocationBarCode(locationBarCode, InventoryTaskStatus.OPERATING, {
				skip: (newPage - 1) * taskTake,
				take: taskTake,
			});
		}
	}, [getProductsByLocationBarCode, locationBarCode, taskTake]);

	const handleIncrement = useCallback((): void => {
		if (!formik.values.quantity) {
			formik.setFieldValue('quantity', 1);
			return;
		}
		formik.setFieldValue('quantity', (Number(formik.values.quantity) ?? 0) + 1);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.setFieldValue, formik.values]);

	const handleDecrement = useCallback((): void => {
		if (!Number(formik.values.quantity)) {
			return;
		}
		formik.setFieldValue('quantity', (Number(formik.values.quantity) ?? 0) - 1);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.setFieldValue, formik.values]);

	const confirmQuantity = useCallback((taskId: string): void => {
		const quantity = Number(formik.values.quantity);
		saveTaskCount({
			taskId,
			quantity,
		});
		setExpanded(taskId);
		formik.setFieldValue('quantity', 0);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saveTaskCount, formik.setFieldValue, formik.values]);

	const handleBlurOrEnter = useCallback((
		event: React.KeyboardEvent<HTMLInputElement>,
		task: IProductTask,
	): void => {
		const target = event.target as HTMLInputElement;
		if ((event.key === 'Tab') || (event.key === 'Enter')) {
			event.preventDefault();
			if (task.code === target.value) {
				handleIncrement();
			} else {
				enqueueSnackbar('Código de barras inválido', {
					variant: 'error',
				});
				formik.setFieldValue('productBarCode', '');
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enqueueSnackbar, handleIncrement, formik.setFieldValue]);

	const handleExpanded = useCallback((taskId: string): void => {
		setExpanded((prevState) => (prevState === taskId ? null : taskId));
		formik.setFieldValue('taskId', taskId);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.setFieldValue]);

	if (!loading && productTasks.length === 0) {
		return (
			<Box sx={{ mt: 2 }}>
				<Typography textAlign="center">Não há produtos nesta localização.</Typography>
				<Button
					sx={{ mt: 4, width: '100%' }}
					size="large"
					variant="contained"
					onClick={() => navigate('/app')}
				>
					Voltar para a tela inicial
				</Button>
			</Box>
		);
	}

	if (loading) {
		return <Loading />;
	}

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				<StepperPagination
					count={taskPages}
					page={taskPage + 1}
					itemsPerPage={taskTake}
					onChange={handlePageChange}
				>
					<Box>
						<Typography mt={2} variant="h6" align="center" marginBottom={2}>
							Confirme os itens para armazenagem
						</Typography>
						<Typography variant="caption" mb={2}>
							Localização:
							{' '}
							{formatBarCode(locationBarCode || '')}
						</Typography>
						{productTasks.map((task) => (
							<Accordion
								key={task.id}
								sx={{ marginBottom: 1 }}
								expanded={expanded === task.id}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									onClick={() => handleExpanded(task.id)}
								>
									<Box sx={{ display: 'flex', flexDirection: 'column' }}>
										<Box display="flex" alignItems="center" gap={1}>
											<Typography>{`${task.code} - ${task.description}`}</Typography>
										</Box>
										<Typography sx={{ fontWeight: 'bold', mb: 1 }}>
											{`${task.quantity} ${task.measures[0].description}(s)`}
										</Typography>
									</Box>
								</AccordionSummary>
								<AccordionDetails>
									<Form>
										<Grid container spacing={2}>
											<Grid item xs={12} sm={6}>
												<Input.InputField
													label="Código de Barras"
													id="productBarCode"
													name="productBarCode"
													fullWidth
													onKeyDown={(
														event: React.KeyboardEvent<HTMLInputElement>,
													) => handleBlurOrEnter(event, task)}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Box
													sx={{
														width: '100%',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
													}}
												>
													<IconButton size="large" onClick={() => handleDecrement()}>
														<Remove />
													</IconButton>

													<Input.InputField
														id="quantity"
														name="quantity"
														style={{ width: 100, textAlign: 'center' }}
														type="number"
														required
													/>
													<IconButton size="large" onClick={() => handleIncrement()}>
														<Add />
													</IconButton>
												</Box>
											</Grid>
											<Grid item xs={12} sm={6} display="flex" justifyContent="space-between" marginTop={2} gap={2}>
												{Number(formik.values.quantity) === Number(task.quantity) ? (
													<Button
														variant="contained"
														size="small"
														color="primary"
														sx={{ width: '100%' }}
														onClick={() => confirmQuantity(task.taskId)}
													>
														Confirmar
													</Button>
												) : (
													<Button
														variant="outlined"
														size="small"
														color="error"
														sx={{ width: '100%' }}
														onClick={() => requestConfirm({
															title: 'Confirmar Inconsistência',
															description: 'Existe uma diferença entre a quantidade informada e a esperada, deseja confirmar mesmo assim?',
															callback: () => confirmQuantity(task.taskId),
														})}
													>
														Inconsistente
													</Button>
												)}

											</Grid>
										</Grid>
									</Form>
								</AccordionDetails>
							</Accordion>
						))}

						{confirmationDialog}
					</Box>
				</StepperPagination>
				<Button
					sx={{ mt: 4 }}
					size="large"
					variant="contained"
					onClick={() => navigate('/app/storage')}
				>
					Voltar
				</Button>
			</Form>
		</FormikContext.Provider>
	);
};

export default StorageProducts;
