import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import { AxiosError } from 'axios';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import StatusSaleOrderPresentational from '../../components/StatusSaleOrder/StatusSaleOrder';
import { getStatusSaleOrder, getStatusSaleOrderCache } from '../../services/saleOrder';
import { IStatusSaleOrder, StatusCount } from './StatusSaleOrderAssets';
import { StatusSaleOrderQueryParams } from '../../interfaces/StatusSaleOrderParams';

enum ActionType {
	LOADING,
	STATUS_SALE_ORDER,
}

interface IState {
	loading: boolean;
	statusSaleOrder: IStatusSaleOrder[];
    statusSaleOrderPage: number;
    statusSaleOrderPages: number;
    statusSaleOrderTake: number;
	statusCounts: StatusCount[];
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.STATUS_SALE_ORDER; payload: {
        statusSaleOrder: IStatusSaleOrder[],
        statusSaleOrderPage: number,
        statusSaleOrderPages: number,
        statusSaleOrderTake: number,
		statusCounts: StatusCount[]
    } };

const initialState: IState = {
	loading: false,
	statusSaleOrder: [],
	statusSaleOrderPage: 0,
	statusSaleOrderPages: 0,
	statusSaleOrderTake: 25,
	statusCounts: [],
};

let lastQueryParams: StatusSaleOrderQueryParams;

interface IStatusSaleOrderActions {
	setLoading(loading: boolean): void;
    getStatusSaleOrder(params: StatusSaleOrderQueryParams): void;
	getStatusSaleOrderCache(): void;
}

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.STATUS_SALE_ORDER:
			return {
				...state,
				statusSaleOrder: action.payload.statusSaleOrder,
				statusSaleOrderPage: action.payload.statusSaleOrderPage,
				statusSaleOrderPages: action.payload.statusSaleOrderPages,
				statusSaleOrderTake: action.payload.statusSaleOrderTake,
				statusCounts: action.payload.statusCounts,
			};
		default:
			throw new Error();
	}
};

const StatusSaleOrderActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): IStatusSaleOrderActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getStatusSaleOrder(queryParams: StatusSaleOrderQueryParams) {
			actions.setLoading(true);
			const take = queryParams.take ?? 25;
			const params = { ...queryParams, skip: queryParams.skip * take };
			lastQueryParams = params;
			getStatusSaleOrder(params)
				.then((response) => {
					dispatch({
						type: ActionType.STATUS_SALE_ORDER,
						payload: {
							statusSaleOrder: response.data.data,
							statusSaleOrderTake: take,
							statusSaleOrderPage: queryParams.skip,
							statusSaleOrderPages: response.data.count,
							statusCounts: response.data.statusCounts,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu ao obter o status da ordem de venda. Tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		getStatusSaleOrderCache() {
			getStatusSaleOrderCache()
				.then((response) => {
					if (response.data.hasStatusSaleOrder) {
						actions.getStatusSaleOrder(lastQueryParams);
					}
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao verificar ordens de venda. Tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				});
		},
	};

	return actions;
};

const StatusSaleOrderContainer = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => StatusSaleOrderActions(dispatch, enqueueSnackbar),
		[dispatch, enqueueSnackbar],
	);

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<StatusSaleOrderPresentational {...state} {...actions} />
	);
};

export default StatusSaleOrderContainer;
