import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import UserPresentational from '../../components/User/User';
import { getUsers, changePassword, handleUserActiveStatus } from '../../services/user';
import { User } from './UserAssets';
import { UserQueryParams } from '../../interfaces/UserQueryParams';

enum ActionType {
	LOADING,
	USERS,
	UPDATE_USER,
	FILTERS,
}

interface IState {
	loading: boolean;
	users: User[];
	usersPages: number;
	usersPage: number;
	usersTake: number;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| {
		type: ActionType.USERS; payload: {
			users: User[],
			usersPages: number,
			usersPage: number,
			usersTake: number,
		}
	}
	| { type: ActionType.UPDATE_USER; payload: { user: User } };

interface IUserActions {
	setLoading(loading: boolean): void;
	getUsers(queryParams: UserQueryParams): void;
	changePassword(id: string, password: string, changePasswordLogin: boolean): void;
	handleUserActiveStatus(id: string, active: boolean): void;
	handleEdit(id: string): void;
}

const initialState: IState = {
	loading: false,
	users: [],
	usersPages: 0,
	usersPage: 0,
	usersTake: 10,
};

let lastQueryParams: UserQueryParams;

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.USERS:
			return {
				...state,
				users: action.payload.users,
				usersPages: action.payload.usersPages,
				usersPage: action.payload.usersPage,
				usersTake: action.payload.usersTake,
			};
		case ActionType.UPDATE_USER:
			return {
				...state,
				users: state.users.map((
					user,
				) => (user.id === action.payload.user.id ? action.payload.user : user)),
			};
		default:
			throw new Error();
	}
};

const UserActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
): IUserActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		handleEdit(id: string) {
			navigate(`/edit/${id}`);
		},
		getUsers(queryParams: UserQueryParams, setLoading?: boolean) {
			actions.setLoading(setLoading !== false);
			const take = queryParams.take ?? 10;
			const params = { ...queryParams, skip: queryParams.skip * take };

			getUsers(params).then((response) => {
				dispatch({
					type: ActionType.USERS,
					payload: {
						users: response.data.data,
						usersPages: response.data.count,
						usersPage: queryParams.skip,
						usersTake: take,
					},
				});
				actions.setLoading(false);
				lastQueryParams = {
					...queryParams,
					skip: response.data.data.length === 1 ? 0 : queryParams.skip,
				};
			});
		},
		changePassword(id: string, password: string, changePasswordLogin: boolean) {
			changePassword(id, password, changePasswordLogin)
				.then((response) => {
					enqueueSnackbar(response.data.message, { variant: 'success' });
					actions.getUsers(lastQueryParams, false);
				})
				.catch(() => {
					throw new Error('Ocorreu um erro ao atualizar a senha do usuário.');
				});
		},
		handleUserActiveStatus(id: string, active: boolean): void {
			handleUserActiveStatus(id, active)
				.then((response) => {
					enqueueSnackbar(response.data.message, { variant: 'success' });
					dispatch({ type: ActionType.UPDATE_USER, payload: { user: response.data.data } });
					actions.getUsers(lastQueryParams, false);
				})
				.catch(() => {
					throw new Error('Ocorreu um erro ao atualizar o status do usuário.');
				});
		},
	};

	return actions;
};

const UserContainer = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const actions = useMemo(() => UserActions(
		dispatch,
		enqueueSnackbar,
		navigate,
	), [dispatch, enqueueSnackbar, navigate]);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <UserPresentational {...state} {...actions} />;
};

export default UserContainer;
