import React, { useCallback } from 'react';
import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import { Paper, Box } from '@mui/material';
import { useManageColumns } from '../../hooks/useManageColumns';
import useColumns from '../../hooks/useColumns';
import { fiscalDocumentEntryColumns } from '../../constants/fiscalDocumentEntryColumns';
import { IFiscalDocument } from '../../containers/FiscalDocumentEntry/FiscalDocumentEntryAssets';
import { ToolbarComponent } from '../Common/Datagrid/DataGridToolBar';
import { AlertTypes } from '../../enums/ConfirmationDialogType';
import useConfirmationDialog from '../../hooks/useConfirmationDialog';

const localStorageKey = 'fiscalDocumentEntryColumns';

const columnsVisibility = [
	'status',
	'emissionDate',
	'nrInvoice',
	'businessPartnerName',
	'value',
	'actions',
];

interface FiscalDocumentEntryProps {
	rows: IFiscalDocument[];
	loading: boolean;
	sortModel: GridSortModel;
	onChangePage(take: number): void;
	onChangePageSize(pageSize: number): void;
	onSortModelChange(sortModel: GridSortModel): void;
	fiscalDocumentTake: number;
	fiscalDocumentPage: number;
	fiscalDocumentPages: number;
	handleDeleteInvoice(id: string): void;
	handleEdit(id: string): void;
}

const FiscalDocumentEntryTable = ({
	rows,
	loading,
	sortModel,
	onChangePage,
	onChangePageSize,
	onSortModelChange,
	fiscalDocumentTake,
	fiscalDocumentPage,
	fiscalDocumentPages,
	handleDeleteInvoice,
	handleEdit,
}: FiscalDocumentEntryProps): JSX.Element => {
	const { requestConfirm, confirmationDialog } = useConfirmationDialog();

	const handleDelete = useCallback((id: string) => {
		requestConfirm({
			description: 'Tem certeza que deseja excluir o documento fiscal selecionado?',
			alertType: AlertTypes.ERROR,
			callback: () => handleDeleteInvoice(id),
		});
	}, [handleDeleteInvoice, requestConfirm]);

	const { columns, defaultVisibility } = useColumns({
		columnsVisibility,
		defaultColumnsOverride: fiscalDocumentEntryColumns,
		includeStatusColumn: false,
		onDeleteClick: handleDelete,
		onEditClick: handleEdit,
	});

	const {
		currentColumns,
		columnVisibilityModel,
		handleColumnVisibilityModelChange,
		onRestoreClick,
	} = useManageColumns({
		initialColumns: columns,
		initialVisibility: defaultVisibility,
		localStorageKey,
		isDraggable: true,
	});

	return (
		<Box component={Paper} sx={{ width: '100%' }}>
			<DataGrid
				autoHeight
				rows={rows}
				rowCount={fiscalDocumentPages}
				pagination
				paginationMode="server"
				sortingMode="server"
				columns={currentColumns}
				page={fiscalDocumentPage}
				pageSize={fiscalDocumentTake}
				rowsPerPageOptions={[10, 25, 50, 100]}
				loading={loading}
				sortModel={sortModel}
				onPageChange={onChangePage}
				onPageSizeChange={onChangePageSize}
				onSortModelChange={onSortModelChange}
				columnVisibilityModel={columnVisibilityModel}
				onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
				components={{ Toolbar: ToolbarComponent(onRestoreClick) }}
				disableSelectionOnClick
				disableColumnMenu
			/>
			{confirmationDialog}
		</Box>
	);
};

export default FiscalDocumentEntryTable;
