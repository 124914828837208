import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import StorageLocationPresentational from '../../../components/Mobile/Storage/StorageLocation';
import { LocationOriginQueryParams } from './StorageAssets';
import { getLocationOriginOperating, LocationTask } from '../../../services/inventoryTask';

enum ActionType {
	LOADING,
	TASKS,
}

interface IState {
	loading: boolean;
	locationTasks: LocationTask[];
	taskPages: number,
	taskPage: number,
	taskTake: number,
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.TASKS; payload: {
		locationTasks: LocationTask[],
		taskPages: number,
		taskPage: number
	} };

interface IStorageActions {
	setLoading(loading: boolean): void;
	getLocationOriginOperating(
		params: LocationOriginQueryParams,
	): void;
}

const initialState: IState = {
	loading: false,
	locationTasks: [],
	taskPages: 0,
	taskPage: 0,
	taskTake: 1,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.TASKS:
			return {
				...state,
				locationTasks: action.payload.locationTasks,
				taskPages: action.payload.taskPages,
				taskPage: action.payload.taskPage,
			};
		default:
			throw new Error();
	}
};

const StorageActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): IStorageActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},

		getLocationOriginOperating(
			params: LocationOriginQueryParams,
		) {
			actions.setLoading(true);
			const queryParams = {
				...params,
				skip: params.skip,
				take: params.take,
			};
			getLocationOriginOperating(queryParams).then(
				(response) => {
					dispatch({
						type: ActionType.TASKS,
						payload: {
							locationTasks: response.data.data,
							taskPages: response.data.count,
							taskPage: params.skip,
						},
					});
				},
			).catch((error: AxiosError) => {
				enqueueSnackbar(
					error.response?.data.message || 'Algum erro ocorreu ao obter a localização de origem. Tente novamente ou contate um administrador.',
					{ variant: 'error' },
				);
			}).finally(() => {
				actions.setLoading(false);
			});
		},
	};

	return actions;
};

const StorageLocation = ():JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => StorageActions(dispatch, enqueueSnackbar),
		[dispatch, enqueueSnackbar],
	);

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<StorageLocationPresentational {...state} {...actions} />
	);
};

export default StorageLocation;
