import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import { IBusinessPartner } from '../../containers/BusinessPartner/BusinessPartnerAssets';
import useColumns from '../../hooks/useColumns';
import { businessPartnerColumns } from '../../constants/businessPartnerColumns';
import { useManageColumns } from '../../hooks/useManageColumns';
import { ToolbarComponent } from '../Common/Datagrid/DataGridToolBar';
import useConfirmationDialog from '../../hooks/useConfirmationDialog';
import { AlertTypes } from '../../enums/ConfirmationDialogType';

interface BusinessPartnerTableProps {
	loading: boolean;
	rows: IBusinessPartner[];
	businessPartnersPages: number;
	businessPartnersPage: number;
	businessPartnersTake: number;
	sortModel: GridSortModel;
	onChangePage(businessPartnersTake: number): void;
	onChangePageSize(pageSize: number): void;
	onSortModelChange(sortModel: GridSortModel): void
	handleEdit(id: string): void;
	handleDeleteBusinessPartner: (id: string) => void;
	onDetailsClick(id: string): void;
	onChangeActiveStatus(id: string, active: boolean): void;
}

const localStorageKey = 'businessPartnerColumns';

const columnsVisibility = [
	'type',
	'marketName',
	'legalEntity',
	'taxId',
	'actions',
];

const BusinessPartnerTable = ({
	loading,
	rows,
	businessPartnersPages,
	businessPartnersPage,
	businessPartnersTake,
	sortModel,
	onChangePage,
	onChangePageSize,
	onSortModelChange,
	handleDeleteBusinessPartner,
	handleEdit,
	onDetailsClick,
	onChangeActiveStatus,
}: BusinessPartnerTableProps): JSX.Element => {
	const { requestConfirm, confirmationDialog } = useConfirmationDialog();

	const handleDelete = useCallback((id: string) => {
		requestConfirm({
			description: 'Tem certeza que deseja excluir o parceiro de negócios selecionado? Se houver unidades relacionadas elas também serão excluidas.',
			alertType: AlertTypes.ERROR,
			callback: () => handleDeleteBusinessPartner(id),
		});
	}, [requestConfirm, handleDeleteBusinessPartner]);

	const { columns, defaultVisibility } = useColumns({
		columnsVisibility,
		defaultColumnsOverride: businessPartnerColumns,
		includeStatusColumn: false,
		onEditClick: handleEdit,
		onDeleteClick: handleDelete,
		onDetailsClick,
		onChangeActiveStatus,
	});

	const {
		currentColumns,
		columnVisibilityModel,
		handleColumnVisibilityModelChange,
		onRestoreClick,
	} = useManageColumns({
		initialColumns: columns,
		initialVisibility: defaultVisibility,
		localStorageKey,
		isDraggable: true,
	});

	return (
		<Box component={Paper} sx={{ width: '100%' }}>
			<DataGrid
				autoHeight
				rows={rows}
				rowCount={businessPartnersPages}
				pagination
				paginationMode="server"
				sortingMode="server"
				columns={currentColumns}
				page={businessPartnersPage}
				pageSize={businessPartnersTake}
				rowsPerPageOptions={[10, 25, 50, 100]}
				loading={loading}
				sortModel={sortModel}
				onPageChange={onChangePage}
				onPageSizeChange={onChangePageSize}
				onSortModelChange={onSortModelChange}
				columnVisibilityModel={columnVisibilityModel}
				onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
				components={{ Toolbar: ToolbarComponent(onRestoreClick) }}
				disableSelectionOnClick
				disableColumnMenu
			/>
			{confirmationDialog}
		</Box>
	);
};

export default BusinessPartnerTable;
