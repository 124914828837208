import { StatusInvoice } from '../containers/FiscalDocumentEntry/FiscalDocumentEntryAssets';

export const StatusInvoiceLabels = {
	[StatusInvoice.PENDING]: 'Pendente',
	[StatusInvoice.IN_PROGRESS]: 'Em andamento',
	[StatusInvoice.COMPLETED]: 'Concluído',
};

export const statusList = [
	{ id: StatusInvoice.PENDING, label: 'Pendente' },
	{ id: StatusInvoice.IN_PROGRESS, label: 'Em andamento' },
	{ id: StatusInvoice.COMPLETED, label: 'Concluído' },
];
