import React, { useCallback, useRef } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Paper } from '@mui/material';

interface IStepperProps {
	count: number;
	page: number;
	itemsPerPage: number;
	onChange: (page: number) => void;
	children: React.ReactNode;
	isPaper?: boolean;
}

const StepperPagination = ({
	count, page, itemsPerPage, onChange, children, isPaper = false,
}: IStepperProps): JSX.Element => {
	const theme = useTheme();
	const totalPages = Math.max(1, Math.ceil(count / itemsPerPage));
	const touchStartX = useRef(0);
	const touchEndX = useRef(0);

	const handleNext = useCallback(() => {
		if (page < totalPages) {
			onChange(page + 1);
		}
	}, [page, totalPages, onChange]);

	const handleBack = useCallback(() => {
		if (page > 1) {
			onChange(page - 1);
		}
	}, [page, onChange]);

	const handleSwipe = useCallback((): void => {
		if (touchStartX.current - touchEndX.current > 50) {
			handleNext();
		} else if (touchEndX.current - touchStartX.current > 50) {
			handleBack();
		}
	}, [handleNext, handleBack]);

	const handleTouchStart = (e: React.TouchEvent):void => {
		touchStartX.current = e.touches[0].clientX;
	};

	const handleTouchEnd = (e: React.TouchEvent):void => {
		touchEndX.current = e.changedTouches[0].clientX;
		handleSwipe();
	};

	return (
		<Box
			onTouchStart={handleTouchStart}
			onTouchEnd={handleTouchEnd}
		>
			<Box component={isPaper ? Paper : Box} p={isPaper ? 2 : 0}>
				{children}
				<MobileStepper
					variant="text"
					steps={totalPages}
					sx={{ mt: 2 }}
					position="static"
					activeStep={page - 1}
					nextButton={(
						<Button size="small" onClick={handleNext} disabled={page >= totalPages}>
							Avançar
							{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
						</Button>
					)}
					backButton={(
						<Button size="small" onClick={handleBack} disabled={page <= 1}>
							{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
							Voltar
						</Button>
					)}
				/>
			</Box>
		</Box>
	);
};

StepperPagination.defaultProps = {
	isPaper: false,
};

export default StepperPagination;
