import React, { Dispatch, Reducer, useReducer } from 'react';
import ClientPresentational from '../../components/Client/Client';
import { getClients, approveClient } from '../../services/client';

enum ActionType {
	LOADING,
	CLIENTS,
}

interface IState {
	count: number;
	loading: boolean;
	clients: any[];
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.CLIENTS; payload: { clients: any[], count: number } };

interface IClientActions {
	setLoading(loading: boolean): void;
    getClients(): void;
    approveClient(id: string): void;
}

const initialState: IState = {
	count: 0,
	loading: false,
	clients: [],
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.CLIENTS:
			return {
				...state,
				clients: action.payload.clients,
				count: action.payload.count,
			};
		default:
			throw new Error();
	}
};

const ClientActions = (dispatch: Dispatch<TAction>): IClientActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getClients() {
			actions.setLoading(true);
			getClients().then((response) => {
				dispatch({
					type: ActionType.CLIENTS,
					payload: {
						clients: response.data.data,
						count: response.data.count,
					},
				});
				actions.setLoading(false);
			});
		},
		approveClient(id: string) {
			actions.setLoading(true);
			approveClient(id).then(() => {
				actions.setLoading(false);
			});
		},
	};

	return actions;
};

const Client = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const actions = ClientActions(dispatch);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <ClientPresentational {...state} {...actions} />;
};

export default Client;
