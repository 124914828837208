/* eslint-disable import/no-duplicates */
import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { StatusOperationLabels } from './statusOperationLabels';
import { StatusOperation } from '../containers/StatusSaleOrder/StatusSaleOrderAssets';
import { applyAppropriateMask } from '../helpers/masks';
import { currencyBRLMask } from '../helpers/intl';
import { formatDateToString } from '../helpers/Utils';
import TruncateTooltipCell from '../components/Common/Datagrid/TruncateTooltipCell';

export const statusSaleOrderColumns: GridColDef[] = [
	{
		field: 'statusOperation',
		headerName: 'Status',
		flex: 0.2,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={StatusOperationLabels[params.value as StatusOperation]} />
		),
	},
	{
		field: 'code',
		headerName: 'Pedido',
		flex: 0.2,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={params.value} />
		),
	},
	{
		field: 'emissionDate',
		headerName: 'Emissão',
		flex: 0.2,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={formatDateToString(params.row.emissionDate)} />
		),
	},
	{
		field: 'totalOrder',
		headerName: 'Total',
		align: 'right',
		flex: 0.2,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={currencyBRLMask(params.value)} />
		),
	},
	{
		field: 'clientTaxId',
		headerName: 'CNPJ/CPF',
		flex: 0.25,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={applyAppropriateMask(params.value)} />
		),
	},
	{
		field: 'clientName',
		headerName: 'Cliente',
		flex: 0.6,
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={`${params.row.clientCode} - ${params.row.clientName}`} />
		),
	},
	{
		field: 'courierName',
		headerName: 'Transportadora',
		flex: 0.5,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={params.value} />
		),
	},
	{
		field: 'invoice',
		headerName: 'Nota/Série',
		flex: 0.2,
		renderCell: (params: GridRenderCellParams) => {
			const { statusInvoiceItems } = params.row;
			const invoiceInfo = statusInvoiceItems && statusInvoiceItems.length > 0
				? `${statusInvoiceItems[0].nrInvoice}/${statusInvoiceItems[0].serie}`
				: '-';
			return (
				<TruncateTooltipCell value={invoiceInfo} />
			);
		},
	},
	{
		field: 'shippedUserName',
		headerName: 'Operador',
		flex: 0.3,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={params.row?.statusInvoiceItems[0]?.shippedUserName || '-'} />
		),
	},
	{
		field: 'shippedDate',
		headerName: 'Data do Checkout',
		flex: 0.25,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={`${params.row?.statusInvoiceItems[0]?.shippedDate ? format(new Date(params.row?.statusInvoiceItems[0]?.shippedDate), 'dd/MM/yyyy HH:mm') : '-'}`} />
		),
	},
	{
		field: 'clientBranch',
		headerName: 'Filial',
		width: 120,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={params.value} />
		),
	},
];
