/* eslint-disable react/jsx-props-no-spreading */
import React, {
	Dispatch,
	Reducer,
	useContext,
	useMemo,
	useReducer,
} from 'react';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { ICompany } from '../../Company/CompanyAssets';
import { IBranch } from '../../Branch/BranchAssets';
import CompanyBranchSelection from '../../../components/Mobile/CompanyBranchSelection/CompanyBranchSelection';
import {
	ChangeBranchPreference, setBranch, setBranchName, setCompanyId, setBranchId, setToken,
} from '../../../services/auth';
import { getUserBranchesByCompany, getUserCompanies } from '../../../services/user';
import { BranchContext } from '../../../contexts/BranchContext';

enum ActionType {
    LOADING,
    COMPANY,
    BRANCH,
    SELECTED_COMPANY,
    SELECTED_BRANCH,
}

interface IState {
    loading: boolean;
    companies: ICompany[];
    branches: IBranch[];
    selectedCompany?: ICompany;
    selectedBranch?: IBranch;
    count: number;
}

type TAction =
    | { type: ActionType.LOADING; payload: { loading: boolean } }
    | { type: ActionType.COMPANY; payload: { companies: ICompany[]; count: number } }
    | { type: ActionType.BRANCH; payload: { branches: IBranch[]; count: number } }
    | { type: ActionType.SELECTED_COMPANY; payload: { selectedCompany: ICompany } }
    | { type: ActionType.SELECTED_BRANCH; payload: { selectedBranch: IBranch } };
interface ICompanyBranchSelectionActions {
    setLoading(loading: boolean): void;
    getUserCompanies(): void;
    getBranches(companyId: string): void;
    setBranch(data: ChangeBranchPreference): void;
}

const initialState: IState = {
	loading: false,
	companies: [],
	branches: [],
	selectedCompany: undefined,
	selectedBranch: undefined,
	count: 0,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.COMPANY:
			return {
				...state,
				companies: action.payload.companies,
				count: action.payload.count,
			};
		case ActionType.BRANCH:
			return {
				...state,
				branches: action.payload.branches,
				count: action.payload.count,
			};
		case ActionType.SELECTED_COMPANY:
			return {
				...state,
				selectedCompany: action.payload.selectedCompany,
				selectedBranch: undefined,
				branches: [],
			};
		case ActionType.SELECTED_BRANCH:
			return { ...state, selectedBranch: action.payload.selectedBranch };
		default:
			throw new Error();
	}
};

const CompanyBranchSelectionActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
	updateBranchId: (branchId: string) => void,
): ICompanyBranchSelectionActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getUserCompanies() {
			actions.setLoading(true);
			getUserCompanies().then((response) => {
				dispatch({
					type: ActionType.COMPANY,
					payload: {
						companies: response.data.data,
						count: response.data.count,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Erro ao carregar empresas.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},
		getBranches(companyId: string) {
			actions.setLoading(true);
			getUserBranchesByCompany(companyId).then((response) => {
				dispatch({
					type: ActionType.BRANCH,
					payload: {
						branches: response.data.data,
						count: response.data.count,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Erro ao carregar filiais.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},
		setBranch(data: ChangeBranchPreference) {
			setBranch(data).then((response) => {
				enqueueSnackbar('Filial alterada com sucesso.', {
					variant: 'success',
				});
				updateBranchId(data.branchId);
				setToken(response.data.token);
				setBranchName(response.data.branchName);
				setCompanyId(response.data.companyId);
				setBranchId(response.data.branchId);
				navigate('/app');
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Erro ao mudar de filial.', {
					variant: 'error',
				});
			});
		},
	};

	return actions;
};

const CompanyBranchSelectionContainer: React.FC = () => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const { updateBranchId } = useContext(BranchContext);
	const navigate = useNavigate();

	const actions = useMemo(
		() => CompanyBranchSelectionActions(dispatch, enqueueSnackbar, navigate, updateBranchId),
		[enqueueSnackbar, navigate, updateBranchId],
	);

	return (
		<CompanyBranchSelection {...state} {...actions} />
	);
};

export default CompanyBranchSelectionContainer;
