/* eslint-disable no-param-reassign */
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Box,
	Button,
	Grid,
	Paper,
	Typography,
	IconButton,
	Stack,
} from '@mui/material';
import {
	TreeItem,
	TreeView,
} from '@mui/lab';
import {
	Add,
	ChevronRight,
	Edit,
	ExpandMore,
	Delete,
	AddCircle,
} from '@mui/icons-material';
import { Form, useFormik, FormikContext } from 'formik';
import {
	ILocation,
	ILocationRequest,
	ILocationType,
	OperationType,
} from '../../containers/Location/LocationAssets';
import Autocomplete from '../Common/Form/Autocomplete';
import Input from '../Common/Form/Input';
import useConfirmationDialog from '../../hooks/useConfirmationDialog';
import LocationModal from './Modals/LocationModal';
import { formatBarCode } from '../../helpers/masks';
import { ICompanyWithoutDetails } from '../../containers/Company/CompanyAssets';
import { BranchParams } from '../../containers/Inventory/InventoryAssets';
import { IBranch } from '../../containers/Branch/BranchAssets';
import { Loading } from '../Common/Loading';
import NoDataPage from '../Common/NoDataPage';
import { COMPANY_ID_KEY, BRANCH_ID_KEY } from '../../services/auth';

interface LocationEditProps {
  loading: boolean;
  locationsMain: ILocation[];
  getLocationTypes: () => void;
  getLocationsMain: () => void;
  getAllNodeIds: (locationsMain: ILocation[]) => void;
  allNodeIds: string[];
  locationTypes: ILocationType[];
  saveLocation: (data: ILocationRequest) => void;
  updateLocation: (id: string, data: Partial<ILocationRequest>) => void;
  deleteLocation: (id: string) => void;
  companies: ICompanyWithoutDetails[];
  getCompanies: () => void;
  getBranches: (params: BranchParams) => void;
  branches: IBranch[];
}

export const initialValues = {
	name: '',
	locationTypeId: '',
	newLocationTypeId: '',
	locationId: '',
	barCode: '',
	barCodeInitial: '',
	barCodeNumber: '',
	childName: '',
	companyId: '',
	branchId: '',
	prefix: '',
};

const LocationEdit = ({
	loading,
	locationsMain,
	getLocationsMain,
	deleteLocation,
	updateLocation,
	saveLocation,
	locationTypes,
	companies,
	getCompanies,
	getLocationTypes,
	getBranches,
	branches,
	allNodeIds,
	getAllNodeIds,
}: LocationEditProps): JSX.Element => {
	const [selectedLocation, setSeletedLocation] = useState<ILocation | null>(null);
	const [filteredTypes, setFilteredTypes] = useState<ILocationType[]>([]);
	const [openModal, setOpenModal] = useState(false);
	const [operationType, setOperationType] = useState<OperationType | null>(null);
	const [isEditing, setIsEditing] = useState(false);

	const { requestConfirm, confirmationDialog } = useConfirmationDialog();

	const handlerModalLocation = useCallback((operation: OperationType | null = null): void => {
		setOpenModal(true);
		setOperationType(operation);
	}, []);

	const handleModalSubmit = useCallback((values: typeof initialValues) => {
		if (operationType === OperationType.STORAGE) {
			const data = {
				barCode: values.prefix.concat(values.barCodeNumber || ''),
				name: values.name,
				locationTypeId: values.locationTypeId || '',
				companyId: values.companyId || '',
				branchId: values.branchId || '',
			};
			saveLocation(data as ILocationRequest);
		} else {
			saveLocation({
				locationId: values.locationId,
				locationTypeId: values.newLocationTypeId,
				name: values.name,
				barCode: values.barCodeInitial.concat(values.prefix).concat(values.barCodeNumber),
			});
		}
		setOpenModal(false);
	}, [operationType, saveLocation]);

	useEffect(() => {
		if (operationType === OperationType.STORAGE) {
			setFilteredTypes(locationTypes.filter((type) => Number(type.level) === 0));
		} else if (selectedLocation) {
			const selectedType = locationTypes.find(
				(type) => type.id === selectedLocation.locationTypeId,
			);
			setFilteredTypes(
				locationTypes.filter((type) => type.level > (selectedType?.level || 0)),
			);
		} else {
			setFilteredTypes(locationTypes);
		}
	}, [locationTypes, operationType, selectedLocation]);

	const formik = useFormik({
		initialValues: {
			...initialValues,
			companyId: localStorage.getItem(COMPANY_ID_KEY) || '',
			branchId: localStorage.getItem(BRANCH_ID_KEY) || '',
		},
		onSubmit: (values) => {
			handleModalSubmit(values);
			formik.resetForm();
		},
	});

	const handleLocationSelect = useCallback((locationTree: ILocation) => {
		formik.setFieldValue('locationId', locationTree.id);
		setSeletedLocation(locationTree);
		formik.setFieldValue('childName', locationTree.name);
		formik.setFieldValue('locationTypeId', locationTree.locationTypeId);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCloseModal = useCallback(() => {
		setOpenModal(false);
		formik.setFieldValue('name', '');
		formik.setFieldValue('newLocationTypeId', '');
		formik.setFieldValue('barCodeNumber', '');
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getLocationsMain();
		getLocationTypes();
	}, [getLocationTypes, getLocationsMain]);

	useEffect(() => {
		getAllNodeIds(locationsMain);
	}, [getAllNodeIds, locationsMain]);

	const handleDelete = useCallback((id: string) => {
		deleteLocation(id);
		setSeletedLocation(null);
		formik.resetForm();
	}, [deleteLocation, formik]);

	const renderTreeItems = useCallback(
		(locations: Array<ILocation>) => {
			const treeItems = locations.map((locationTree: ILocation) => {
				locationTree.barCodeNumber = locationTree.barCode.slice(-2);
				locationTree.barCodeInitial = locationTree.barCode.slice(0, -2);

				return (
					<TreeItem
						key={locationTree.id}
						nodeId={locationTree.id}
						label={locationTree.name}
						onClick={() => handleLocationSelect(locationTree)}
					>
						{locationTree.locations && locationTree.locations.length > 0
							? renderTreeItems(locationTree.locations)
							: null}
					</TreeItem>
				);
			});

			return treeItems;
		},
		[handleLocationSelect],
	);

	const locationNameMemo = useMemo(() => (
		<Box>
			{isEditing ? (
				<Input.InputField
					id="childName"
					name="childName"
					label="Nome da Localização"
					onBlur={() => {
						updateLocation(formik.values.locationId, {
							name: formik.values.childName,
						});
						setIsEditing(false);
					}}
					onKeyDown={(event) => {
						if (event.key === 'Enter') {
							updateLocation(formik.values.locationId, {
								name: formik.values.childName,
							});
							setIsEditing(false);
						}
					}}
					autoComplete="off"
					autoFocus
					fullWidth
					required
				/>
			) : (
				<Typography
					variant="h5"
					color="primary"
					style={{ cursor: 'pointer' }}
				>
					{formik.values.childName || selectedLocation?.name}
				</Typography>
			)}
		</Box>
	), [
		formik.values.childName,
		formik.values.locationId,
		isEditing,
		selectedLocation?.name,
		updateLocation,
	]);

	const treeViewComponent = useMemo(() => {
		if (allNodeIds.length === 0) {
			return null;
		}

		return (
			<TreeView
				defaultCollapseIcon={<ExpandMore />}
				defaultExpandIcon={<ChevronRight />}
				defaultExpanded={allNodeIds}
			>
				{renderTreeItems(locationsMain)}
			</TreeView>
		);
	}, [allNodeIds, locationsMain, renderTreeItems]);

	const hasFilteredLocations = useMemo(
		() => filteredTypes.length > 0,
		[filteredTypes],
	);

	return (
		<>
			{loading
				? (<Loading />)
				: (
					<FormikContext.Provider value={formik}>
						<Form noValidate>
							<Box>
								<Typography mb={4} color="primary" variant="h4" gutterBottom>
									Gerenciar Localizações
								</Typography>
								<Box
									display="flex"
									p={2}
									minHeight="70vh"
									className="content"
									component={Paper}
								>
									<Box width="300px" pr={2} borderRight="1px solid #ddd">
										<Box
											display="flex"
											alignItems="center"
											alignContent="center"
											justifyContent="space-between"
										>
											<Box>
												<Typography variant="h6">
													Estrutura de Localização
												</Typography>
											</Box>
											<Box>
												<IconButton
													color="primary"
													onClick={() => handlerModalLocation(OperationType.STORAGE)}
												>
													<AddCircle sx={{ fontSize: 30 }} color="primary" />
												</IconButton>
											</Box>
										</Box>

										{treeViewComponent}
									</Box>

									<Box flex={1} pl={2}>
										{selectedLocation ? (
											<Box>
												<Stack direction="row" mb={2} alignItems="center" spacing={1}>
													{locationNameMemo}

													<IconButton
														color="primary"
														aria-label="edit"
														onClick={() => setIsEditing(true)}
													>
														<Edit />
													</IconButton>
													<IconButton
														color="error"
														aria-label="delete"
														onClick={() => requestConfirm({
															description:
				'Tem certeza que deseja remover a localização selecionada?',
															callback: () => handleDelete(selectedLocation.id),
														})}
													>
														<Delete />
													</IconButton>
												</Stack>
												<Grid container spacing={2} alignItems="center">
													<Grid item xs={6} md={3}>
														<Autocomplete
															label="Tipo de Localização"
															valueLabel="level"
															valueKey="id"
															labelKey="name"
															name="locationTypeId"
															options={locationTypes}
															disabled
															fullWidth
															required
														/>
													</Grid>

													<Grid item xs={12} md={6}>
														<Typography variant="subtitle1" color="#00000099">
															Código de Barras
														</Typography>

														<Typography
															variant="subtitle1"
															sx={{
																overflowY: 'auto',
															}}
														>
															{formatBarCode(selectedLocation?.barCode || '')}
														</Typography>
													</Grid>

													{hasFilteredLocations ? (
														<Grid item xs={12}>
															<Box mt={2} display="flex" gap={1}>
																<Button
																	variant="contained"
																	color="primary"
																	startIcon={<Add />}
																	sx={{ textTransform: 'none' }}
																	onClick={() => handlerModalLocation(OperationType.LOCATION)}
																	disabled={!selectedLocation}
																>
																	Adicionar Sublocalização
																</Button>
															</Box>
														</Grid>
													) : null}
												</Grid>
											</Box>
										) : (
											<Box mt={4}>
												<NoDataPage
													title="Nenhuma Localização Selecionada"
													description="Selecione uma localização para adicionar um nível inferior."
												/>
											</Box>
										)}
									</Box>
								</Box>
							</Box>
							<LocationModal
								open={openModal}
								onClose={handleCloseModal}
								operationType={operationType}
								loading={loading}
								locationTypes={filteredTypes}
								companies={companies}
								getCompanies={getCompanies}
								branches={branches}
								getBranches={getBranches}
								selectedLocation={selectedLocation}
							/>
						</Form>
					</FormikContext.Provider>
				)}

			{confirmationDialog}
		</>
	);
};

export default LocationEdit;
