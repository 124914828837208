import React, {
	useCallback, useMemo, useState,
} from 'react';
import { Box, Link } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { GridSortDirection } from '@mui/x-data-grid';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import FiscalDocumentEntryTable from './FiscalDocumentEntryTable';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import DrawerFilter from '../Common/DrawerFilter';
import FiscalDocumentEntryFilter from './FiscalDocumentEntryFilter';
import { IFiscalDocument } from '../../containers/FiscalDocumentEntry/FiscalDocumentEntryAssets';
import useDataGridManagement from '../../hooks/useDataGridManagement';
import { FiscalDocumentQueryParams } from '../../interfaces/FiscalDocumentParams';
import { normalizeDataGridOrderBy } from '../../helpers/Utils';
import FiscalDocumentEntryXMLModal from './FiscalDocumentEntryXMLModal';

interface FiscalDocumentEntryProps {
	loading: boolean;
	fiscalDocuments: IFiscalDocument[];
	getFiscalDocuments(params: FiscalDocumentQueryParams): void;
	fiscalDocumentTake: number;
	fiscalDocumentPage: number;
	fiscalDocumentPages: number;
	handleDeleteInvoice(id: string): void;
	handleEdit(id: string): void;
	confirmUploadXml(data: FormData): void;
}

const FiscalDocumentEntry = ({
	loading,
	fiscalDocuments,
	getFiscalDocuments,
	fiscalDocumentTake,
	fiscalDocumentPage,
	fiscalDocumentPages,
	handleDeleteInvoice,
	handleEdit,
	confirmUploadXml,
}: FiscalDocumentEntryProps): JSX.Element => {
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [filterValues, setFilterValues] = useState<Partial<FiscalDocumentQueryParams>>({});
	const [isModalOpen, setModalOpen] = useState(false);

	const {
		setFilter,
		sortModel,
		onChangePage,
		onChangePageSize,
		onSortModelChange,
	} = useDataGridManagement<FiscalDocumentQueryParams>({
		initialPageSize: fiscalDocumentTake,
		initialPage: fiscalDocumentPage,
		fetchData: getFiscalDocuments,
	});

	const toggleFilterDrawer = useCallback(() => {
		setFilterDrawerOpen(!isFilterDrawerOpen);
	}, [isFilterDrawerOpen]);

	const handleOpenModal = useCallback(() => {
		setModalOpen(true);
	}, []);

	const handleCloseModal = useCallback(() => {
		setModalOpen(false);
	}, []);

	const headerButtonsProps: PageHeaderButtonProps[] = [
		{
			variant: 'contained',
			color: 'primary',
			type: 'submit',
			fullWidth: true,
			component: Link,
			to: 'edit',
			text: 'Enviar XML',
			onClick: handleOpenModal,
		},
		{
			variant: 'contained',
			color: 'primary',
			onClick: toggleFilterDrawer,
			text: 'Filtro',
			startIcon: <FilterListIcon />,
		},
	];

	const sendFilter = useCallback((values: Partial<FiscalDocumentQueryParams>) => {
		const pageFilter = {
			...values,
			skip: 0,
			take: fiscalDocumentTake,
			orderBy: normalizeDataGridOrderBy(sortModel) as {
                name: GridSortDirection,
                value: GridSortDirection,
            },
		};
		setFilter(pageFilter);
	}, [fiscalDocumentTake, setFilter, sortModel]);

	const handleFilterSubmit = useCallback((newFilterValues: Partial<FiscalDocumentQueryParams>) => {
		setFilterValues(newFilterValues);
		setFilterDrawerOpen(false);
		sendFilter(newFilterValues);
	}, [sendFilter]);

	const fiscalDocumentEntryFilterMemo = useMemo(() => (
		<DrawerFilter open={isFilterDrawerOpen} onClose={toggleFilterDrawer}>
			<FiscalDocumentEntryFilter
				sendFilter={handleFilterSubmit}
				initialValues={filterValues}
			/>
		</DrawerFilter>
	), [filterValues, handleFilterSubmit, isFilterDrawerOpen, toggleFilterDrawer]);

	const fiscalDocumentEntryXMLModalMemo = useMemo(() => (
		<FiscalDocumentEntryXMLModal
			handleClose={handleCloseModal}
			open={isModalOpen}
			confirmUploadXml={confirmUploadXml}
		/>
	), [confirmUploadXml, handleCloseModal, isModalOpen]);

	return (
		<Box className="content">
			{fiscalDocumentEntryFilterMemo}
			{fiscalDocumentEntryXMLModalMemo}
			<PageHeader title="Entrada de Documento Fiscal" buttons={headerButtonsProps} />
			<FiscalDocumentEntryTable
				rows={fiscalDocuments}
				loading={loading}
				sortModel={sortModel}
				onChangePageSize={onChangePageSize}
				onChangePage={onChangePage}
				onSortModelChange={onSortModelChange}
				fiscalDocumentTake={fiscalDocumentTake}
				fiscalDocumentPage={fiscalDocumentPage}
				fiscalDocumentPages={fiscalDocumentPages}
				handleDeleteInvoice={handleDeleteInvoice}
				handleEdit={handleEdit}
			/>
		</Box>
	);
};

export default FiscalDocumentEntry;
