import React, {
	Dispatch,
	Reducer,
	useReducer,
} from 'react';
import { ProviderClient } from '../../components/Common/ProviderClient/ProviderClientAssets';
import ProviderEditPresentational from '../../components/Provider/ProviderEdit';
import { saveProvider } from '../../services/provider';

enum ActionType {
	LOADING,
	PROVIDER,
}

interface IState {
	loading: boolean;
	provider?: any;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.PROVIDER; payload: { provider: any } };

interface IProviderActions {
	setLoading(loading: boolean): void;
    getProviderById(id: string): void;
    saveProvider({ id, data }: {
        id?: string | undefined;
        data: ProviderClient;
    }): void;
}

const initialState: IState = {
	loading: false,
	provider: null,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.PROVIDER:
			return { ...state, provider: action.payload.provider };
		default:
			throw new Error();
	}
};

const ProviderActions = (dispatch: Dispatch<TAction>): IProviderActions => ({
	setLoading(loading: boolean) {
		dispatch({ type: ActionType.LOADING, payload: { loading } });
	},
	getProviderById(id: string) {
		this.setLoading(true);
		// getProviderById(id).then(providers => {
		//   dispatch({ type: ActionType.PROVIDERS, payload: { providers } })
		//   this.setLoading(false);
		// });
	},
	saveProvider({ id, data }: { id?: string; data: ProviderClient }) {
		// this.setLoading(true);

		if (id) {
			// this.setLoading(false);
		} else {
			saveProvider(data).then(() => {
				// this.setLoading(false);
			});
		}
	},
});

const Provider = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const actions = ProviderActions(dispatch);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <ProviderEditPresentational {...state} {...actions} />;
};

export default Provider;
