import React, {
	useCallback, useEffect, useState,
} from 'react';
import {
	Box,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Button,
	Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Form, FormikContext, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import Input from '../../Common/Form/Input';
import { LocationOriginQueryParams } from '../../../containers/Mobile/Storage/StorageAssets';
import { Loading } from '../../Common/Loading';
import { formatBarCode, removeMaskBarCode } from '../../../helpers/masks';
import StepperPagination from '../../Common/StepperPagination';
import { LocationTask } from '../../../services/inventoryTask';

interface StorageLocationProps {
	getLocationOriginOperating: (
		params: LocationOriginQueryParams
	) => void;
	locationTasks: LocationTask[];
	taskPages: number;
	taskPage: number;
	taskTake: number;
	loading: boolean;
}

const StorageLocation = ({
	getLocationOriginOperating,
	locationTasks,
	taskPages,
	taskPage,
	taskTake,
	loading,
}: StorageLocationProps): JSX.Element => {
	const [expanded, setExpanded] = useState<null | string>(null);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	useEffect(() => {
		getLocationOriginOperating(
			{
				skip: taskPage,
				take: taskTake,
			},
		);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (locationTasks.length > 0) {
			setExpanded(locationTasks[0].locationDestinyBarCode);
		}
	}, [locationTasks]);

	const formik = useFormik({
		initialValues: {
			barCodeLocation: '',
		},
		onSubmit: (values) => {
			const barCodeValue = removeMaskBarCode(values.barCodeLocation);
			if (barCodeValue !== locationTasks[0]?.locationDestinyBarCode) {
				enqueueSnackbar('Código de barras inválido', {
					variant: 'error',
				});
				formik.setFieldValue('barCodeLocation', '');
			} else {
				navigate(barCodeValue);
			}
		},
	});

	const handlePageChange = useCallback((newPage: number): void => {
		setExpanded(null);
		getLocationOriginOperating({
			skip: (newPage - 1) * taskTake,
			take: taskTake,
		});
	}, [getLocationOriginOperating, taskTake]);

	const handleBlurOrEnterLocation = useCallback((
		event: React.KeyboardEvent<HTMLInputElement>,
	): void => {
		if ((event.key === 'Tab') || (event.key === 'Enter')) {
			formik.handleSubmit();
			event.preventDefault();
		}
	}, [formik]);

	const handleExpanded = useCallback((barCode: string): void => {
		setExpanded((prevState) => (prevState === barCode ? null : barCode));
	}, []);

	if (!loading && locationTasks.length === 0) {
		return (
			<Box sx={{ mt: 2 }}>
				<Typography textAlign="center">Não há produtos nesta localização.</Typography>
				<Button
					sx={{ mt: 4, width: '100%' }}
					size="large"
					variant="contained"
					onClick={() => navigate('/app')}
				>
					Voltar para a tela inicial
				</Button>
			</Box>
		);
	}

	if (loading) {
		return <Loading />;
	}

	return (
		<FormikContext.Provider value={formik}>
			<Form onSubmit={formik.handleSubmit}>
				<Box>
					<StepperPagination
						count={taskPages}
						page={taskPage + 1}
						itemsPerPage={taskTake}
						onChange={handlePageChange}
					>
						<Typography mt={2} variant="h6" align="center" marginBottom={2}>
							Siga para as localizações abaixo:
						</Typography>
						{locationTasks.map((location) => (
							<Accordion
								key={location.locationDestinyBarCode}
								sx={{ marginBottom: 2, borderRadius: 1, boxShadow: 1 }}
								expanded={expanded === location.locationDestinyBarCode}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									onClick={() => handleExpanded(location.locationDestinyBarCode)}
								>
									<Box display="flex" alignItems="center" gap={1}>
										<Typography sx={{ textTransform: 'uppercase' }}>
											{`${formatBarCode(location.locationDestinyBarCode)}`}
										</Typography>
									</Box>
								</AccordionSummary>
								<AccordionDetails>
									<Grid>
										<Box>
											<Typography textAlign="center" gutterBottom>
												Informe a localização de destino
											</Typography>

											<Input.InputField
												id="barCodeLocation"
												name="barCodeLocation"
												label="Código de Barras da Localização"
												format={(value) => formatBarCode(value).toUpperCase()}
												autoComplete="off"
												autoFocus
												onKeyDown={(
													event: React.KeyboardEvent<HTMLInputElement>,
												) => handleBlurOrEnterLocation(event)}
												fullWidth
												required
											/>
											<Button
												sx={{ width: '100%', mt: 2 }}
												size="large"
												variant="outlined"
												type="submit"
											>
												Confirmar Código de Barras
											</Button>
										</Box>
									</Grid>
								</AccordionDetails>
							</Accordion>
						))}

					</StepperPagination>
					<Button
						sx={{ mt: 4 }}
						size="large"
						variant="contained"
						onClick={() => navigate('/app')}
					>
						Voltar
					</Button>
				</Box>
			</Form>
		</FormikContext.Provider>
	);
};

export default StorageLocation;
