import React, {
	Dispatch, Reducer, useReducer, useMemo,
} from 'react';
import { AxiosError } from 'axios';
import {
	OptionsObject, SnackbarMessage, SnackbarKey, useSnackbar,
} from 'notistack';
import FiscalDocumentEntryEditPresentational from '../../components/FiscalDocumentEntry/FiscalDocumentEntryEdit';
import { IInvoiceHeader, IInvoiceItem } from './FiscalDocumentEntryAssets';
import { ProductsIntegration, ProductsInvoiceQueryParams } from '../../interfaces/FiscalDocumentParams';
import { confirmIntegrationProducts, getProductsInvoice } from '../../services/invoice';
import { getProductsWithoutDetails } from '../../services/product';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import { IProductWithoutDetails } from '../Product/ProductAssets';

enum ActionType {
	LOADING,
	PRODUCT_INVOICE,
	PRODUCT
}

interface IState {
	loading: boolean;
	invoiceItems: IInvoiceItem[];
	invoice?: IInvoiceHeader;
	products: IProductWithoutDetails[]
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.PRODUCT; payload: { products: IProductWithoutDetails[] } }
	| { type: ActionType.PRODUCT_INVOICE; payload: {
		invoiceItems: IInvoiceItem[];
		invoice: IInvoiceHeader;
	} };

interface IFiscalDocumentEntryEditActions {
	setLoading(loading: boolean): void;
	getProductsInvoice(invoiceId: string, params?: ProductsInvoiceQueryParams): void;
	getProductsWithoutDetails(queryParams: ProductQueryParams): void;
	confirmIntegrationProducts(data: ProductsIntegration[]): void;
}

const initialState: IState = {
	loading: false,
	invoiceItems: [],
	invoice: undefined,
	products: [],
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.PRODUCT_INVOICE:
			return {
				...state,
				productsInvoice: action.payload.invoiceItems,
				invoice: action.payload.invoice,
			};
		case ActionType.PRODUCT:
			return {
				...state,
				products: action.payload.products,
			};
		default:
			throw new Error();
	}
};

const FiscalDocumentEntryEditActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (
		message: SnackbarMessage,
		options?: OptionsObject | undefined
	) => SnackbarKey,
): IFiscalDocumentEntryEditActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getProductsInvoice(invoiceId: string) {
			actions.setLoading(true);
			getProductsInvoice(invoiceId)
				.then((response) => {
					dispatch({
						type: ActionType.PRODUCT_INVOICE,
						payload: {
							invoice: response.data.invoice,
							invoiceItems: response.data.items,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message
			|| 'Algum erro ocorreu ao obter os produtos da NF. Tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		getProductsWithoutDetails(queryParams: ProductQueryParams) {
			getProductsWithoutDetails(queryParams).then((response) => {
				dispatch({
					type: ActionType.PRODUCT,
					payload: {
						products: response.data.data,
					},
				});
			});
		},
		confirmIntegrationProducts(data: ProductsIntegration[]) {
			confirmIntegrationProducts(data)
				.then((response) => {
					enqueueSnackbar(response.data.message, { variant: 'success' });
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
					actions.setLoading(false);
				});
		},

	};

	return actions;
};

const FiscalDocumentEntryEditContainer = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => FiscalDocumentEntryEditActions(dispatch, enqueueSnackbar),
		[enqueueSnackbar],
	);
	return (
		/* eslint-disable react/jsx-props-no-spreading */
		<FiscalDocumentEntryEditPresentational {...state} {...actions} />
	);
};

export default FiscalDocumentEntryEditContainer;
