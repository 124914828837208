import React, {
	useCallback, useEffect, useMemo, useState,
} from 'react';
import {
	DataGrid,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import LoadingButton from '@mui/lab/LoadingButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import { PageHeader } from '../../Common/PageHeader/PageHeader';
import { Loading } from '../../Common/Loading';
import { PurchaseOrderQueryParams } from '../../../interfaces/PurchaseOrderQueryParams';
import { PurchaseOrderFilter } from './PurchaseOrderFilter';
import useColumns from '../../../hooks/useColumns';
import { useManageColumns } from '../../../hooks/useManageColumns';
import { ToolbarComponent } from '../../Common/Datagrid/DataGridToolBar';
import { IBranch, IPurchaseOrder } from '../../../containers/Order/PurchaseOrderAssets';
import DetailsDrawer from '../Drawers/DetailsDrawer';
import useDataGridManagement from '../../../hooks/useDataGridManagement';

export interface PurchaseOrderProps {
	loading: boolean;
	purchasesPages: number;
	purchasesPage: number;
	purchasesTake: number;
	purchases: IPurchaseOrder[];
	branches: IBranch[];
	getPurchases(queryParams: PurchaseOrderQueryParams): void;
	getBranches(): void;
	onNFDownloadClick(id: string): void;
	onNFViewAsHTMLClick(id: string): void;
	exportPurchases(queryParams: PurchaseOrderQueryParams): void;
	exportPurchasesApportionments(queryParams: PurchaseOrderQueryParams): void;
}

const localStorageKey = 'purchaseOrderColumns';

const columnsVisibility = [
	'nrOrder',
	'providerName',
	'totalValueWithoutDiscount',
	'totalDiscount',
	'totalValue',
	'branch',
	'orderIssuance',
	'hasInvoices',
	'invoiceNumber',
	'invoiceSerie',
	'invoiceIssuance',
	'actions',
];

const optionalColumnsVisibility = [
	'OSNumber',
	'deliveryBranch',
	'costType',
	'createdAt',
	'invoiceType',
	'observation',
	'paymentCode',
	'paymentCodeDescription',
	'providerCnpj',
	'providerCode',
	'providerStore',
	'requesterLogin',
	'requesterName',
	'status',
	'updatedAt',
	'userUpdate',
];

const PurchaseOrder = (props: PurchaseOrderProps): JSX.Element => {
	const {
		loading,
		purchases,
		purchasesPages,
		purchasesPage,
		purchasesTake,
		branches,
		getPurchases,
		getBranches,
		onNFDownloadClick,
		onNFViewAsHTMLClick,
		exportPurchases,
		exportPurchasesApportionments,
	} = props;
	const [openDetails, setOpenDetails] = useState<IPurchaseOrder | undefined>(undefined);

	useEffect(() => {
		getBranches();
	}, [getBranches, getPurchases]);

	const onDetailsClick = useCallback((id: string) => {
		const selectedOrder = purchases
			.find((purchase) => purchase.id === id);
		setOpenDetails(selectedOrder);
	}, [purchases]);

	const onCloseDetails = useCallback(() => {
		setOpenDetails(undefined);
	}, []);

	const {
		filter,
		setFilter,
		onChangePage,
		onChangePageSize,
	} = useDataGridManagement<PurchaseOrderQueryParams>({
		initialPageSize: purchasesTake,
		initialPage: purchasesPage,
		fetchData: getPurchases,
	});

	const sendFilter = useCallback((values: any) => {
		const pageFilter = {
			...values,
			skip: 0,
		};

		setFilter(pageFilter);
	}, [setFilter]);

	const { columns, defaultVisibility } = useColumns({
		columnsVisibility,
		optionalColumnsVisibility,
		apportionAction: true,
		onNFDownloadClick,
		onNFViewAsHTMLClick,
		onDetailsClick,
	});

	const {
		currentColumns,
		columnVisibilityModel,
		handleColumnVisibilityModelChange,
		onRestoreClick,
	} = useManageColumns({
		initialColumns: columns,
		initialVisibility: defaultVisibility,
		localStorageKey,
		isDraggable: true,
	});

	const onExportButtonClick = useCallback(() => {
		if (filter) {
			const exportColumns = currentColumns
				.filter((column) => columnVisibilityModel[column.field] !== false && column.field !== 'actions')
				.map((exportColumn) => ({ field: exportColumn?.field, label: exportColumn?.headerName }));

			exportPurchases({ ...filter, columns: exportColumns });
		}
	}, [filter, columnVisibilityModel, exportPurchases, currentColumns]);

	const onExportApportionmentsButtonClick = useCallback(() => {
		if (filter) {
			const exportColumns = currentColumns
				.filter((column) => columnVisibilityModel[column.field] !== false && column.field !== 'actions')
				.map((exportColumn) => ({ field: exportColumn?.field, label: exportColumn?.headerName }));

			exportPurchasesApportionments({ ...filter, columns: exportColumns });
		}
	}, [filter, columnVisibilityModel, exportPurchasesApportionments, currentColumns]);

	const pageHeaderMemo = useMemo(() => <PageHeader title="Gerenciamento de Rateios" />, []);

	const filterMemo = useMemo(() => (
		<PurchaseOrderFilter
			branches={branches}
			sendFilter={sendFilter}
		/>
	), [branches, sendFilter]);

	return (
		<div className="content">
			{pageHeaderMemo}
			{filterMemo}
			{loading && <Loading />}
			<Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
				<LoadingButton
					sx={{ mt: 2 }}
					startIcon={<GetAppIcon />}
					loading={loading}
					variant="contained"
					color="primary"
					type="submit"
					onClick={onExportButtonClick}
				>
					Exportar pedidos
				</LoadingButton>
				<LoadingButton
					sx={{ mt: 2 }}
					startIcon={<GetAppIcon />}
					loading={loading}
					variant="contained"
					color="primary"
					type="submit"
					onClick={onExportApportionmentsButtonClick}
				>
					Exportar pedidos com rateios
				</LoadingButton>
			</Box>
			{!loading && (
				<Box component={Paper} sx={{ width: '100%', mt: 2 }}>
					<DataGrid
						autoHeight
						rows={purchases}
						rowCount={purchasesPages}
						pagination
						paginationMode="server"
						columns={currentColumns}
						page={purchasesPage}
						pageSize={purchasesTake}
						rowsPerPageOptions={[10, 25, 50, 100]}
						loading={loading}
						onPageChange={onChangePage}
						onPageSizeChange={onChangePageSize}
						disableSelectionOnClick
						disableColumnMenu
						columnVisibilityModel={columnVisibilityModel}
						onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
						components={{ Toolbar: ToolbarComponent(onRestoreClick) }}
					/>
				</Box>
			)}
			<DetailsDrawer
				openDetails={openDetails}
				onClose={onCloseDetails}
			/>
		</div>
	);
};

export default PurchaseOrder;
