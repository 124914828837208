import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import StorageProductsPresentational from '../../../components/Mobile/Storage/StorageProducts';
import {
	saveTaskCount,
	SaveTaskType,
} from '../../../services/storage';
import { InventoryTaskStatus } from '../../../enums/InventoryTaskStatus';
import { IProductTask, LocationOriginQueryParams, ProductsByLocationQueryParams } from './StorageAssets';
import { removeMaskBarCode } from '../../../helpers/masks';
import { getProductsByLocationBarCode } from '../../../services/inventoryTask';

enum ActionType {
	LOADING,
	TASKS,
}

interface IState {
	loading: boolean;
	productTasks: IProductTask[];
	taskPages: number;
	taskPage: number;
	taskTake: number;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.TASKS; payload: {
		productTasks: IProductTask[],
		taskPages: number,
		taskPage: number
	} };

interface IStorageActions {
	setLoading(loading: boolean): void;
	getProductsByLocationBarCode(
		barCode: string,
		status: InventoryTaskStatus,
		params: LocationOriginQueryParams
	): void;
	saveTaskCount(data: SaveTaskType): void
}

const initialState: IState = {
	loading: false,
	productTasks: [],
	taskPages: 0,
	taskPage: 0,
	taskTake: 1,
};

let lastBarCode: string;
let lastQueryParams: ProductsByLocationQueryParams;
let lastCount: number;

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.TASKS:
			return {
				...state,
				productTasks: action.payload.productTasks,
				taskPages: action.payload.taskPages,
				taskPage: action.payload.taskPage,
			};
		default:
			throw new Error();
	}
};

const StorageActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
): IStorageActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},

		getProductsByLocationBarCode(
			barCode: string,
			status: InventoryTaskStatus,
			params: LocationOriginQueryParams,
		) {
			lastBarCode = removeMaskBarCode(barCode);

			actions.setLoading(true);
			getProductsByLocationBarCode(lastBarCode, status, params).then(
				(response) => {
					dispatch({
						type: ActionType.TASKS,
						payload: {
							productTasks: response.data.products,
							taskPages: response.data.count,
							taskPage: params.skip,
						},
					});

					lastQueryParams = params;
					lastCount = response.data.count;
				},
			).catch((error: AxiosError) => {
				enqueueSnackbar(
					error.response?.data.message || 'Algum erro ocorreu ao obter a localização de origem. Tente novamente ou contate um administrador.',
					{ variant: 'error' },
				);
			})
				.finally(() => {
					actions.setLoading(false);
				});
		},

		saveTaskCount(data: SaveTaskType) {
			saveTaskCount(data).then((response) => {
				enqueueSnackbar(response.data.message, { variant: 'success' });
				if (lastCount === 1) {
					navigate('/app/storage');
				} else {
					actions.getProductsByLocationBarCode(
						lastBarCode,
						InventoryTaskStatus.OPERATING,
						{
							skip: 0,
							take: lastQueryParams.take,
						},
					);
				}
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			});
		},

	};

	return actions;
};

const StorageCheck = ():JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const actions = useMemo(
		() => StorageActions(dispatch, enqueueSnackbar, navigate),
		[dispatch, enqueueSnackbar, navigate],
	);

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<StorageProductsPresentational {...state} {...actions} />
	);
};

export default StorageCheck;
