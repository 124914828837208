import React, { useCallback } from 'react';
import {
	Box,
	Typography,
	Button,
	Grid,
	Stack,
	Divider,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { DanfeByBarCodeQueryParams, IInvoice } from '../../../containers/Mobile/CheckoutCarrier/CheckoutCarrierAssets';
import { Loading } from '../../Common/Loading';
import StepperPagination from '../../Common/StepperPagination';
import { initialValues } from './CheckoutCarrier';

interface CheckoutProductsProps {
	invoice?: IInvoice;
	handleCancelProducts: () => void;
	loading: boolean;
	invoicePage: number;
	invoiceTake: number;
	getDanfeByBarCode(danfeBarCode: string, params: DanfeByBarCodeQueryParams): void;
}

const CheckoutProducts = ({
	invoice,
	handleCancelProducts,
	getDanfeByBarCode,
	loading,
	invoicePage,
	invoiceTake,
}: CheckoutProductsProps): JSX.Element => {
	const { values } = useFormikContext<typeof initialValues>();

	const handlePageChange = useCallback((newPage: number): void => {
		getDanfeByBarCode(values.danfeBarCode, {
			skip: newPage,
			take: invoiceTake,
		});
	}, [getDanfeByBarCode, invoiceTake, values]);

	if (loading || !invoice) {
		return (
			<Box>
				<Loading />
			</Box>
		);
	}

	return (
		<StepperPagination
			count={invoice.totalItems}
			page={invoicePage}
			itemsPerPage={invoiceTake}
			onChange={handlePageChange}
			isPaper
		>
			<Box sx={{ mb: 2 }}>
				<Typography variant="h6" color="primary">
					{`Nota Fiscal ${invoice?.nrInvoice} / Série ${invoice?.serie}`}
				</Typography>
				<Typography>
					{`Cliente: ${invoice?.bpClientStore}`}
				</Typography>
				<Typography>
					{`Transportadora: ${invoice?.bpCourierStore}`}
				</Typography>
			</Box>
			<Divider />

			<Grid container>
				{invoice?.items.map((invoiceItem) => (
					<Grid item xs={12} sm={6} md={4} key={invoiceItem.product.id}>
						<Box py={1}>
							<Typography fontWeight="bold">{`${invoiceItem.product.code} - ${invoiceItem.product.description}`}</Typography>
							<Typography>
								{`Quantidade: ${invoiceItem.quantity}`}
							</Typography>
						</Box>
						<Divider />
					</Grid>
				))}
			</Grid>
			<Stack
				direction="row"
				gap={2}
				sx={{
					position: 'fixed',
					bottom: 0,
					left: 0,
					right: 0,
					backgroundColor: 'white',
					p: 2,
				}}
			>
				<Button
					variant="outlined"
					fullWidth
					onClick={handleCancelProducts}
				>
					Cancelar
				</Button>
				<Button
					variant="contained"
					fullWidth
					type="submit"
				>
					Confirmar
				</Button>
			</Stack>
		</StepperPagination>
	);
};

CheckoutProducts.defaultProps = {
	invoice: undefined,
};

export default CheckoutProducts;
